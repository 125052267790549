import styled from 'styled-components';
import { StyledPaper } from '../../../../ui-kit/Paper/Paper.styles'

export const FormPaper = styled(StyledPaper)`
  padding: 0;
  overflow: hidden;
`

export const Title = styled.h2`
  font-size: 1.5rem;
`

export const SubPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
  border-bottom: 1px solid rgb(210 210 210 / 50%);
  padding: 0 1rem;
  height: 5.063rem;
`

export const TabContent = styled.div`
  padding: 2rem 1rem 1rem;
`

export const FormContent = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`

export const TabScroll = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none; 
  &::-webkit-scrollbar { 
    display: none;  
  }
  & {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`


