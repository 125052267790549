import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import settingsReducer from './slices/settingsSlice';
import companyReducer from './slices/companySlice';
import toastReducer from './slices/toastSlice';
import { apiSlice } from './slices/apiSlice'; 
import customFieldSliceCreated from './slices/customFieldSliceCreatedSlice';
import selectedTabReducer from './slices/selectedTabSlice';
import { handleApiError } from './slices/errorHandler';
import { surveyFormApiSlice } from './slices/surveyFormApiSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    toast: toastReducer,
    company: companyReducer,
    customFieldSliceCreated: customFieldSliceCreated,
    selectedTab: selectedTabReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [surveyFormApiSlice.reducerPath]: surveyFormApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['api/executeQuery/fulfilled', 'api/executeQuery/rejected'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in the state
        ignoredPaths: ['api.queries.getUsersTemplate({}).data'],
      },
    }).concat(apiSlice.middleware, surveyFormApiSlice.middleware),
});


// Custom middleware to handle API errors
store.subscribe(() => {
  const state = store.getState();
  apiSlice.middleware({
    dispatch: store.dispatch,
    getState: store.getState,
  });
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
