import React, { useState } from 'react'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { formatMessage } from '../../../../locale'
import Paper from '../../../../ui-kit/Paper/Paper'
import Button from '../../../../ui-kit/Button/Button'
import { paths } from '../../../../config/paths'
import { LuPlus } from 'react-icons/lu'
import { SurveyList } from './List/SurveyList'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useExportSurveyResultsMutation, useGetSurveyQuery } from '../../../../slices/surveyApiSlice'
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton, Title } from './SurveyView.style'
import { LuArrowLeft } from 'react-icons/lu'
import { SearchInputForm } from '../../../../ui-kit/Form/components/Input/SearchInput';

const SurveyView: React.FC = () => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const navigate = useNavigate()
  const { intl } = useIntlContext()
   
  const { data: surveyData } = useGetSurveyQuery({ id: surveyId })
  const [exportSurveyResults] = useExportSurveyResultsMutation()

  const { userCompany, permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleExport = async (surveyId: any) => {
    try {
      const { data } = await exportSurveyResults({ id: surveyId })
      if (data) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'survey_results.xlsx'; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1); 
  };


  return (
    <Paper>
      <div style={{
        border: '1px solid rgba(234, 231, 242, 1)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '15px'
      }}>

       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 35 }}>
        <Title>{surveyData?.data?.title}</Title>

        <BackButton
          color={userCompany?.button_color}
          onClick={() => {
          navigate(paths.surveys)
          }}
        >
        <LuArrowLeft />
        {formatMessage({
            id: 'surveys.backTo',
            defaultMessage: 'BACK TO SURVEYS',
        })}
          </BackButton>
        </div>

        <div style={{  borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}/>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px 15px 0 25px' }}>
          <div/>
            {canDo('survey: export survey result') && (
              <Button
                appearance="primary"
                onClick={() => handleExport(surveyId)}
              >           
                {formatMessage({ id: 'surveys.exportSurvyeResults', defaultMessage: 'Export survey results' })}
            </Button>
          )}

      </div>
        <SurveyList searchTerm={searchTerm} currentPage={currentPage} />
        </div>
    </Paper>
  )
}
export default SurveyView;