export enum Reoccurrence {
    EVERY_MONTH = 'every_month',
    THREE_MONTH = '3_months',
    SIX_MONTH = '6_months',
    YEAR = 'year',
    TWO_YEAR = '2_years'
}

export const ReoccurrenceTranslations = {
    [Reoccurrence.EVERY_MONTH]: {
        en: 'Every month',
        sv: 'Varje månad'
    },
    [Reoccurrence.THREE_MONTH]: {
        en: 'Every three months',
        sv: 'Var tredje månad'
    },
    [Reoccurrence.SIX_MONTH]: {
        en: 'Every six months',
        sv: 'Var sjätte månad'
    },
    [Reoccurrence.YEAR]: {
        en: 'Every year',
        sv: 'Varje år'
    },
    [Reoccurrence.TWO_YEAR]: {
        en: 'Every two years',
        sv: 'Vartannat år'
    }
};
