import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { setCredentials, setLoaded, setPermissions } from '../slices/authSlice'
import { paths } from '../config/paths'
import { Handbook } from '../features/Handbook/Handbook'
import { Courses } from '../features/Courses/Courses'
import { CourseForm } from '../features/Courses/components/CourseForm/CourseForm'
import { Library } from '../features/Library/Library'
import { Lessons } from '../features/Lessons/Lessons'
import { LessonForm } from '../features/Lessons/components/LessonForm/LessonForm'
import Login from '../features/Auth/Login/Login'
import BaseLayout from '../components/BaseLayout/BaseLayout'
import AuthLayout from '../components/AuthLayout/AuthLayout'
import { Dashboard } from '../features/Dashboard/Dashboard'
import { UserInfo } from '../interfaces/auth'
import { Index } from '../features/Index/Index'
import { useGetUserInfoQuery, useGetUserPermissionsQuery } from '../slices/usersApiSlices'
import { HandbookPage } from '../features/Handbook/HandbookPage'
import { HandbookForm } from '../features/Handbook/components/HandbookForm/HandbookForm'
import { AnnualPlan } from '../features/AnnualPlan/AnnualPlan'
import { ActionsPlan } from '../features/ActionsPlan/ActionsPlan'
import  ActionPlansForm from '../features/ActionsPlan/components/ActionsPlanForm/ActionsPlansForm'
import { ActionsPlanPage } from '../features/ActionsPlan/ActionsPlanPage'
import { Company2 } from '../features/Company2/Company2'
import { CompanyForm } from '../features/Company2/components/CompanyForm/CompanyForm'
import { Users } from '../features/Users/Users'
import { UsersForm } from '../features/Users/components/UsersForm/UsersForm'
import { Profile } from '../features/Profile/Profile'
import { LibraryForm } from '../features/Library/components/LibraryForm/LibraryForm'
import { LibraryPage } from '../features/Library/LibraryPage'
import ClientCourses from '../features/ClientCourses/ClientCourses'
import ClientCoursesPage from '../features/ClientCourses/ClientCoursesPage'
import ClientCourseLessonPage from '../features/ClientCourses/ClientCourseLessonPage'
import ClientCourseQuizPage from '../features/ClientCourses/ClientCourseQuizPage'
import { ClaimPage } from '../features/Claim/ClaimPage'
import ClaimForm from '../features/Claim/components/ClaimForm/ClaimForm'
import { Claim } from '../features/Claim/Claim'
import { Investigation } from '../features/Investigation/Investigation'
import { InvestigationPage } from '../features/Investigation/InvestigationPage'
import InvestigationForm from '../features/Investigation/components/InvestigationForm/InvestigationForm'
import { VirtualAssistant } from '../features/VirtualAssistant/VirtualAssistant'
import { VirtualAssistantPage } from '../features/VirtualAssistant/VirtualAssistantPage'
import VirtualAssistantForm from '../features/VirtualAssistant/components/VirtualAssistantForm/VirtualAssistantForm'
import { Instructions } from '../features/Instructions/Instructions'
import { InstructionsPage } from '../features/Instructions/InstructionsPage'
import InstructionsForm from '../features/Instructions/components/InstructionsForm/InstructionsForm'
import { Support } from '../features/Support/Support'
import { SupportPage } from '../features/Support/SupportPage'
import SupportForm  from '../features/Support/components/SupportForm/SupportForm'
import ResetPassword from '../features/Auth/Login/ResetPassword'
import TaskType from '../features/TaskType/TaskType'
import TaskTypeForm from '../features/TaskType/Components/TaskTypeForm'
import Tasks from '../features/Tasks/Tasks'
import TaskForm from '../features/Tasks/Components/TaskForm/TaskForm'
import { TaskPage } from '../features/Tasks/TaskPage'
import { UnderConstruction } from '../features/UnderConstruction/UnderConstruction'
import  Survey  from '../features/Survey/Survey'
import SurveyForm from '../features/Survey/components/SurveyForm/SurveyForm'
import PublicSurveyForm from '../features/SurveyClient/PublicSurveyForm/PublicSurveyForm'
import SurveyView from '../features/Survey/components/SurveyView/SurveyView'
import SurveyFormLayout from '../components/SurveyFormLayout/SurveyFormLayout'



const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={paths.root} element={<Index />} />
      <Route path={paths.dashboard} element={<Dashboard />} />


      <Route path={paths.clientCourses} element={<ClientCourses />} />
      <Route path={paths.clientCoursesPage} element={<ClientCoursesPage />} />
      <Route path={paths.clientCoursesLessonPage} element={<ClientCourseLessonPage />} />
      <Route path={paths.clientCoursesQuizPage} element={<ClientCourseQuizPage />} />


      <Route path={paths.courses} element={<Courses />} />
      <Route
        path={`${paths.createCourse}`}
        element={<CourseForm mode="create" />}
      />
      <Route
        path={`${paths.editCourse}`}
        element={<CourseForm mode="edit" />}
      />

      <Route
        path={`${paths.copyCourse}`}
        element={<CourseForm mode="copy" />}
      />

      <Route path={paths.library} element={<Library />} />
      <Route path={paths.lessons} element={<Lessons />} />
      <Route
        path={`${paths.viewLesson}`}
        element={<LessonForm mode="view" />}
      />
      <Route
        path={`${paths.createLesson}`}
        element={<LessonForm mode="create" />}
      />
      <Route
        path={`${paths.editLesson}`}
        element={<LessonForm mode="edit" />}
      />

      <Route path={paths.handbook} element={<Handbook />} />
      <Route path={paths.viewHandbook} element={<HandbookPage />} />
      <Route path={`${paths.createHandbook}`} element={<HandbookForm mode="create" />} />
      <Route path={`${paths.editHandbook}`} element={<HandbookForm mode="edit" />} />

      <Route path={paths.claim} element={<Claim />} />
      <Route path={paths.viewClaim} element={<ClaimPage />} />
      <Route path={`${paths.createClaim}`} element={<ClaimForm mode="create" />} />
      <Route path={`${paths.editClaim}`} element={<ClaimForm mode="edit" />} />

      <Route path={paths.investigation} element={<Investigation />} />
      <Route path={paths.viewInvestigation} element={<InvestigationPage />} />
      <Route path={`${paths.createInvestigation}`} element={<InvestigationForm mode="create" />} />
      <Route path={`${paths.editInvestigation}`} element={<InvestigationForm mode="edit" />} />

      <Route path={paths.viewLibrary} element={<LibraryPage />} />
      <Route path={`${paths.createLibrary}`} element={<LibraryForm mode="create" />} />
      <Route path={`${paths.editLibrary}`} element={<LibraryForm mode="edit" />} />

      <Route path={paths.annual_plan} element={<AnnualPlan />} />

      <Route path={paths.actionsPlan} element={<ActionsPlan />} />
      <Route path={paths.viewActionsPlan} element={<ActionsPlanPage />} />
      <Route path={`${paths.createActionsPlan}`} element={<ActionPlansForm mode="create" />} />
      <Route path={`${paths.editActionsPlan}`} element={<ActionPlansForm mode="edit" />} />

      
      <Route path={paths.company} element={<Company2 />} />
      <Route path={`${paths.createCompany}`} element={<CompanyForm mode="create" />} />
      <Route path={`${paths.editCompany}`} element={<CompanyForm mode="edit" />} />

      <Route path={paths.users} element={<Users />} />
      <Route path={`${paths.createUser}`} element={<UsersForm mode="create" />} />
      <Route path={`${paths.editUser}`} element={<UsersForm mode="edit" />} />
      <Route path={`${paths.profile}`} element={<Profile />} />

      <Route path={paths.virtualAssistant} element={<VirtualAssistant />} />
      <Route path={paths.viewVirtualAssistant} element={<VirtualAssistantPage />} />
      <Route path={`${paths.createVirtualAssistant}`} element={<VirtualAssistantForm mode="create" />} />
      <Route path={`${paths.editVirtualAssistant}`} element={<VirtualAssistantForm mode="edit" />} />

      <Route path={paths.instructions} element={<Instructions />} />
      <Route path={paths.viewInstructions} element={<InstructionsPage />} />
      <Route path={`${paths.createInstructions}`} element={<InstructionsForm mode="create" />} />
      <Route path={`${paths.editInstructions}`} element={<InstructionsForm mode="edit" />} />
       
      <Route path={paths.support} element={<Support />} />
      <Route path={paths.viewSupport} element={<SupportPage />} />
      <Route path={`${paths.createSupport}`} element={<SupportForm mode="create" />} />
      <Route path={`${paths.editSupport}`} element={<SupportForm mode="edit" />} />

      <Route path={paths.taskType} element={<TaskType />} />
      <Route path={paths.createTaskType} element={<TaskTypeForm mode="create" />} />
      <Route path={paths.editTaskType} element={<TaskTypeForm mode="edit" />} />

      <Route path={paths.tasks} element={<Tasks />} />
      <Route path={paths.viewTask} element={<TaskPage />} />
      <Route path={paths.createTask} element={<TaskForm mode="create" />} />
      <Route path={paths.editTask} element={<TaskForm mode="edit" />} />

      <Route path={paths.surveys} element={<Survey />} />
      <Route path={paths.createSurvey} element={<SurveyForm mode='create' />} />
      <Route path={paths.editSurvey} element={<SurveyForm mode='edit'/>} />
      <Route path={paths.copySurvey} element={<SurveyForm mode='copy' />} />
      <Route path={paths.viewSurvey} element={<SurveyView />} />

    </Routes>
  )
}

const AppRouter: React.FC = () => {
  const dispatch = useDispatch()
  const isSpecialHashRoute = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(window.location.pathname);

  if (isSpecialHashRoute) {
    const hashID = window.location.pathname.substring(1);
    localStorage.setItem('hashID', hashID);
  } 

  const { userInfo, isLoaded } = useSelector((state: RootState) => state.auth)
  const { data: user, error, isLoading } = useGetUserInfoQuery({}, {skip: isSpecialHashRoute})
  const { data: permissions } = useGetUserPermissionsQuery({}, {skip: isSpecialHashRoute})

  const [isLoggedOut, setIsLoggedOut] = useState(false)
  const [isJustLoggedIn, setIsJustLoggedIn] = useState(false)
  

  useEffect(() => {
    if (user) {
      dispatch(setCredentials(user as UserInfo))
      if (permissions) {
        dispatch(setPermissions(permissions))
      }
      dispatch(setLoaded())
    }
  }, [user, permissions, dispatch])

  useEffect(() => {
    if (error) {
      dispatch(setLoaded())
    }
  }, [error, dispatch])

  useEffect(() => {
    const handleLogoutEvent = () => {
      setIsJustLoggedIn(false)
      setIsLoggedOut(true)
    }

    window.addEventListener('userLoggedOut', handleLogoutEvent)

    return () => {
      window.removeEventListener('userLoggedOut', handleLogoutEvent)
    }
  }, [])

  useEffect(() => {
    const handleLoginEvent = () => {
      setIsJustLoggedIn(true)
    }

    window.addEventListener('userLoggedIn', handleLoginEvent)

    return () => {
      window.removeEventListener('userLoggedIn', handleLoginEvent)
    }
  }, [])

  if (isLoading) return <div>Loading...</div>
  if (error) console.error(error)

  const isResetPasswordRoute = window.location.pathname === paths.resetPassword


  

  return (
    <BrowserRouter>
      {isSpecialHashRoute &&
        <Routes>
          <Route path={paths.publicSurveyForm} element={<PublicSurveyForm />} />
        </Routes>}
     {isLoaded && (
        <>
          {isJustLoggedIn || (userInfo && !error && !isLoggedOut) ? (
                <BaseLayout>
                  <AppRoutes />
                </BaseLayout>
              ) : (
                <AuthLayout>
                  <Routes>
                    {!isResetPasswordRoute && (
                      <>
                        <Route path={paths.root} element={<Login />} />
                        {isLoggedOut && <Route path="*" element={<Login />} />}
                        {!isLoggedOut && (
                          <Route path="*" element={<Navigate to={paths.root} />} />
                        )}
                      </>
                    )}

                    {isResetPasswordRoute && (
                      <Route path={paths.resetPassword} element={<ResetPassword />} />
                    )}
                  </Routes>
                </AuthLayout>
              )}
            </>
      )}
    </BrowserRouter>
  )
}

export default AppRouter
