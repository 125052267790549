import React from 'react';
import {  useParams } from 'react-router-dom';
import { formatMessage } from '../../../../../locale';
import {
  ListContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from './SurveyList.style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import PaginableList from '../../../../../ui-kit/PaginableListSurveyView';
import { useGetSurveyProgressQuery } from '../../../../../slices/surveyApiSlice';
import { SurveyView } from '../../../../../enum/Survey/SurveyView';

export const SurveyList = ({searchTerm, currentPage }: any) => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const { permissions } = useSelector((state: RootState) => state.auth);



  return (
    <PaginableList
      query={useGetSurveyProgressQuery}
      id={surveyId}
      currentPage={currentPage}
      searchTerm={searchTerm}
    >
      {(surveys, isLoading, error, refetch) => {
        if (isLoading) return <div>{formatMessage({ id: 'survey.loading', defaultMessage: 'Loading...' })}</div>;
        if (error) return <div>{formatMessage({ id: 'survey.errorLoadingsurvey', defaultMessage: 'Error while loading survey' })}</div>;

        return (
            <div style={{ padding: '20px', border: '' }}>
              <ListContainer>
            <Table>
              <Thead>
                <Tr>
                    <Th>
                      {formatMessage({ id: 'surveys.department', defaultMessage: 'Department' })}
                    </Th>
                    <Th>{formatMessage({ id: 'surveys.status', defaultMessage: 'Status' })}</Th>                
                    <Th>
                      {formatMessage({ id: 'surveys.progress', defaultMessage: 'Progress' })}
                    </Th>      
                </Tr>
              </Thead>
              <Tbody>
                {surveys?.map((survey: any) => (
                  <Tr key={survey.id}>
                    <Td>{survey?.department || formatMessage({ id: 'surveys.notChosen', defaultMessage: 'Not chosen' })}</Td>
                    <Td>{survey?.result === SurveyView.COMPLETED ? 
                      formatMessage({ id: 'surveys.completed', defaultMessage: 'Completed' }) : 
                      survey?.result === SurveyView.FAILED ? 
                        formatMessage({ id: 'surveys.failed', defaultMessage: 'Failed' }) :
                        survey?.result === SurveyView.IN_PROGRESS ?
                          formatMessage({ id: 'surveys.in_progress', defaultMessage: 'In progress' }) : 
                        survey?.result === SurveyView.TO_BEGIN &&
                          formatMessage({ id: 'surveys.to_begin', defaultMessage: 'To begin' })
                      }</Td>
                    <Td style={{}}>{survey?.progress}%</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>          
          </ListContainer>
        </div>
        );
      }}
    </PaginableList>
  );
};

export default SurveyList;
