import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  FormikInput,
  Row,
  FormikTextarea,
} from '../../../../ui-kit';
import { FormContent, Actions } from '../SurveyForm/SurveyForm.style';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { useCreateSurveyMutation, useUpdateSurveyMutation, useCopySurveyMutation } from '../../../../slices/surveyApiSlice';
import { paths } from '../../../../config/paths';
import { Button } from '../../../../ui-kit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { handleImageData } from '../../../../slices/apiSlice';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { useGetSubsidiariesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { formatMessage } from '../../../../locale';

interface DetailsProps {
  mode: 'create' | 'edit' | 'copy';
  survey?: any;
  onChanged?: () => void;
}

export const Details = ({ mode, survey, onChanged }: DetailsProps) => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { data: companiesData } = useGetSubsidiariesCompaniesQuery({});

  const [createSurvey] = useCreateSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();
  const [copySurvey] = useCopySurveyMutation();
  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }


  const initialValues = {
    title: survey?.title || '',
    description: survey?.description || '',
    min_required_responses: survey?.min_required_responses || '',
    image: survey?.image || null,
    subsidiary_companies: survey?.subsidiary_companies?.map((company: any) => company.id) || [],
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      let data: any = {
        title: values.title,
        description: values.description,
        min_required_responses: values.min_required_responses,
        subsidiary_companies: values.subsidiary_companies,
      };

      data = handleImageData(values, data, 'image');

      if (mode === 'create') {
        let response = await createSurvey({ data: data }).unwrap();
        navigate(`/surveys/edit/${response.data.id}`);
      } else if (mode === 'edit' && survey.id) {
        await updateSurvey({ id: survey.id, data: data }).unwrap();
        onChanged?.();
        navigate(paths.surveys);
      } else if (mode === 'copy' && survey.id) {
        let response = await copySurvey({ id: survey.id, data: data }).unwrap();
        navigate(`/surveys/edit/${response.data.id}`);
      }
    } catch (error) {
      console.error('Failed to create/update/copy survey:', error);
    } finally {
      setSubmitting(false); 
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, values, handleChange, setFieldValue }) => (
        <Form id="surveyForm" onSubmit={handleSubmit}>
          <FormContent>
            <Row>
              <FormikInput
                label={formatMessage({ id: 'createEditSurvey.titleLabel', defaultMessage: 'Title' })}
                name="title"
                placeholder={formatMessage({ id: 'createEditSurvey.titlePlaceholder', defaultMessage: 'Enter a survey title' })}
                autoComplete="off"
                required
                value={values.title}
                onChange={handleChange}
              />
            </Row>
            <Row>
              <FormikTextarea
                label={formatMessage({ id: 'createEditSurvey.decsLabel', defaultMessage: 'Description' })}
                name="description"
                placeholder={formatMessage({ id: 'createEditSurvey.decsPlaceholder', defaultMessage: 'Enter a survey description' })}
                autoComplete="off"
                required
                value={values.description}
                onChange={handleChange}
              />
            </Row>
           <Row>
              <FormikInput
                label={formatMessage({ id: 'createEditSurvey.minium', defaultMessage: 'Minimum required responses to see results' })}
                name="min_required_responses"
                placeholder={formatMessage({ id: 'createEditSurvey.titlePlaceholder', defaultMessage: 'Enter a survey title' })}
                autoComplete="off"
                required
                value={values.title}
                onChange={handleChange}
              />
            </Row> 
            <Row>
              <ImageUploadWrapper name="image" type="photos" />
            </Row>
          </FormContent>
        {mode === 'edit' && canDo('survey: create') && (
            <Actions style={{ marginTop: '20px' }}>
              <Button appearance="primary" type="submit" disabled={isSubmitting}>
                {mode === 'edit' && formatMessage({ id: 'createEditSurvey.save', defaultMessage: 'Save' })
                }
              </Button>
            </Actions>
          )}
        </Form>
      )}
    </Formik>
  );
};
