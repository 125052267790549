import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionButtons } from '../../components/ActionButtons/ActionButtons'
import { LuPlus } from 'react-icons/lu'
import { Button, TableSurvey } from '../../ui-kit/'
import { paths } from '../../config/paths'
import {
  useSurveyGetAllQuery,
  useDeleteSurveyMutation,
  useUpdateSurveyStartMutation,
} from '../../slices/surveyApiSlice'
import { Actions, SubPageHeader, FormPaper, TableContainer, Title, ResponsiveContainer, ResponsiveHeader, ResponsiveFilters } from './Survey.styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import CopySurveyPopup from './CopySurveyPopup'
import ConfirmationModal from '../../ui-kit/Modal/ConfirmationModal'
import PaginableList from '../../ui-kit/PaginableListSurvey';
import { SearchInputForm } from '../../ui-kit/Form/components/Input/SearchInput';
import CustomSelectMulti from '../../ui-kit/Dropdown/DashboardMultiStatus/DropdownMulti';
import DatePicker from 'react-datepicker'
import { Input } from '../Dashboard/TaskList/TaskList.style';
import Tooltip from '../../ui-kit/ToolTip/TooltipContainer';
import { RiRestartLine } from "react-icons/ri";
import { FilterStatus } from '../../enum/Survey/FilterStatus'
import Hero from '../../ui-kit/Hero/Hero'
import { useMedia } from '../../ui-kit/utils/media' 

const FilterButtons: React.FC<{
  user: any, mapStatuses: any,
  setSelectedStatus: any, selectedStatus: any, startDate: any,
  setStartDate: any, endDate: any, setEndDate: any

}> = ({ user, selectedStatus, setSelectedStatus, mapStatuses, startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div style={{ paddingRight: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', gap: 10, flexWrap: 'wrap' }}>
      
      <CustomSelectMulti
        value={selectedStatus}
        onChange={(selectedOption: any) => setSelectedStatus(selectedOption)}
        options={mapStatuses}
        placeholder={formatMessage({ id: 'surveys.filterByStatus', defaultMessage: 'Filter by status...' })}
      />
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
      <DatePicker
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
          placeholderText={formatMessage({ id: 'surveys.filterFrom', defaultMessage: 'Filter from' })}
          dateFormat="yyyy-MM-dd"
          customInput={<Input color={user} />}
        />
        <DatePicker
          selected={endDate}
          onChange={(date: any) => setEndDate(date)}
          placeholderText={formatMessage({ id: 'surveys.filterTo', defaultMessage: 'Filter to' })}
          dateFormat="yyyy-MM-dd"
          customInput={<Input color={user} />}
      />
      <Tooltip title={formatMessage({ id: 'dashboard.resetDate', defaultMessage: 'Reset dates' })}>
      <Button 
        color={user}
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
        }}
      >
        <RiRestartLine style={{ fontSize: 14 }} />
      </Button>
        </Tooltip>
      </div>
    </div>
  );
};


 
export const TaskList = () => {
  const { isMobile, isTablet, isWide } = useMedia()
  const navigate = useNavigate()
  const { intl } = useIntlContext();

  const [deleteSurvey] = useDeleteSurveyMutation()
  const [startSurvey] = useUpdateSurveyStartMutation()


  const { userCompany, permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false)
  const [selectedSurveyId, setSelectedSurveyId] = useState<number | null>(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [statusPaginated, setStatusPaginated] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [mapStatuses, setMapStatuses] = useState<any[]>([]);

  useEffect(() => {
    const options = [
      { value: FilterStatus.DRAFT, title: formatMessage({ id: 'surveys.draft', defaultMessage: 'Draft' }) },
      { value: FilterStatus.PUBLISHED, title: formatMessage({ id: 'surveys.published', defaultMessage: 'Published' }) },
      { value: FilterStatus.STARTED,  title: formatMessage({ id: 'surveys.started', defaultMessage: 'Started' }) },
      { value: FilterStatus.CLOSED,  title: formatMessage({ id: 'surveys.closed', defaultMessage: 'Closed' }) },
    ]
    const sortedOptions = options.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (intl.locale === 'sv') {
        return titleA.localeCompare(titleB, 'sv');
      } else {
        return titleA.localeCompare(titleB, 'en');
      }
    });
    
    setMapStatuses(sortedOptions);
  }, [intl.locale]);
  
  const optionStatuses = mapStatuses.map((status: any) => ({ value: status.value, label: status.title }));

  return (
    <PaginableList
      query={useSurveyGetAllQuery} currentPage={currentPage}
      searchTerm={searchTerm} statusPaginated={selectedStatus}
      startDate={startDate} endDate={endDate}
    >
      {(surveys, isLoading, error, refetch) => {

        if (error) return <div><p>{formatMessage({ id: 'surveys.error', defaultMessage: 'Error while loading surveys' })}</p></div>

        
        const editSurveyAction = (surveyId: number) => navigate(`/surveys/edit/${surveyId}`)
        const copySurveyAction = (surveyId: number) => navigate(`/surveys/copy/${surveyId}`)
        const viewSurveyAction = (surveyId: number) => navigate(`/surveys/${surveyId}`)
        
          const superCopySurveyAction = (surveyId: number) => {
            setSelectedSurveyId(surveyId)
            setCopyPopupOpen(true)
          }
        
          const closeCopyPopup = () => {
            setCopyPopupOpen(false)
            setSelectedSurveyId(null)
          }
        
          const openModal = (surveyId: number) => {
            setUserIdToDelete(surveyId);
            setShowModal(true);
          };
        
          const onCancel = () => {
            setShowModal(false);
            setUserIdToDelete(null);
          };
        
          const deleteSurveyAction = (surveyId: number) => {
             if (userIdToDelete !== null) {
              try {
                deleteSurvey(surveyId)
                  .then(() => {
                    refetch();
                    setShowModal(false);
                    setUserIdToDelete(null);
                  })
                  .catch((error) => {
                    console.error('Failed to delete survey:', error);
                  });
              } catch (error) {
                console.error('Unexpected error:', error);
              }
            } 
          };
            
        
          const startSurveyAction = (surveyId: number) => {
             startSurvey(surveyId).then(() => {
              refetch()
            }) 
          }
        
          const columns = [
            formatMessage({ id: 'surveys.titleLabel', defaultMessage: 'Title' }),
            formatMessage({ id: 'surveys.statusLabel', defaultMessage: 'Status' }),
            formatMessage({ id: 'surveys.startedLabel', defaultMessage: 'Started' }),
            formatMessage({ id: 'surveys.expiredLabel', defaultMessage: 'Expired' }),
            formatMessage({ id: 'userList.actions', defaultMessage: 'Actions' }),
          ]
        
        const surveyTableData: any = surveys?.map((survey: any) => ({
            id: survey.id,
            [columns[0]]: (survey.is_inherited ? '*** ' : '') + survey.title,
            [columns[1]]: survey.status.title,
            [columns[2]]: survey.started_at,
            [columns[3]]: survey.expired_at,
            [columns[4]]: (
              <ActionButtons
                id={survey.id}
                handleEdit={canDo('survey: update') ? editSurveyAction : undefined}
                handleCopy={canDo('survey: update') ? copySurveyAction : undefined}
                handleSuperCopy={canDo('survey: copy to subsidiary') ? superCopySurveyAction : undefined}
                openModal={canDo('survey: delete') ? openModal : undefined}
                handleStart={canDo('survey: start') && survey.status.enum === 'published' ? startSurveyAction : undefined}
              />
            ),
          })) 
        
          const handleSearchChange = (value: string) => {
            setSearchTerm(value);
            setCurrentPage(1); 
        };
        


        const translatedSelectedStatus = selectedStatus.map(status => ({
          value: status.value,
          label: formatMessage({ id: `surveys.${status.value}`, defaultMessage: status.label })
        }));

        return <>
         <FormPaper> 
              <div/>
          <Actions>
          {canDo('survey: create') && (
            <Button appearance="primary" onClick={() => navigate(paths.createSurvey)}>
              <LuPlus size={20} />
              {formatMessage({ id: 'surveys.newSurveyButton', defaultMessage: 'New survey' })}
            </Button>
          )}
        </Actions>
             <Hero
                description={formatMessage({ id: 'hero.description', defaultMessage: 'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.' })}
                imagePath={'assets/hero2.jpg'}
                header={formatMessage({ id: 'hero.header', defaultMessage: 'Welcome to the ' })}
                subHeader={formatMessage({ id: 'hero.subHeaderSurvey', defaultMessage: 'Here you will find Surveys' })}
              />
      <TableContainer>
        {isLoading ? (  
          <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
        ) : (
          <ResponsiveContainer>     
            <ResponsiveHeader>    
            <Title style={{fontSize: isMobile ? '1.5rem' : '1.8rem'}}>
              {formatMessage({ id: 'surveys.title', defaultMessage: 'Surveys' })}
            </Title>
          <div style={{marginTop: isMobile ? '1rem' : '0'}}>
            <SearchInputForm
              name="search"
              placeholder={formatMessage({ id: 'search', defaultMessage: 'Search...' })}
              label=""
              onChange={handleSearchChange}
              onSubmit={() => { }}
              initialValues={{ search: searchTerm }}
          />
          </div>
            </ResponsiveHeader>
          <ResponsiveFilters>                     
            <FilterButtons
            user={userCompany?.button_color}
            mapStatuses={optionStatuses}
            selectedStatus={translatedSelectedStatus}
            setSelectedStatus={setSelectedStatus}
            startDate={startDate} setStartDate={setStartDate}
            endDate={endDate} setEndDate={setEndDate}
          />
          </ResponsiveFilters>
          <TableSurvey
          columns={columns}
          rows={surveyTableData}
          {...(canDo('survey: progress')
            && { onSelect: (id: any) => viewSurveyAction(id) }
 )}
          />
          </ResponsiveContainer>
        )}
      </TableContainer>
      {isCopyPopupOpen && selectedSurveyId !== null && (
        <CopySurveyPopup
          surveyId={selectedSurveyId}
          onClose={closeCopyPopup}
          onCopySuccess={refetch}
        />
      )}
       {showModal && (
         <ConfirmationModal
           header={formatMessage({ id: 'confirmModal.noticeSurvey', defaultMessage: 'Are you sure you want to delete this survey?' })}
           onConfirm={() => userIdToDelete !== null && deleteSurveyAction(userIdToDelete)}
           onCancel={onCancel}
         />
        )}
    </FormPaper>
        </>;
      }}
    </PaginableList>
  );
};

export default TaskList;