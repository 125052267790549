import CustomSelect from '../../Dropdown/CreateTaskDropdown/CreateTaskDropdown'
import { formatMessage } from '../../../locale'
import { Container, Input, P } from './SaveTaskInfoModal.style'


interface SaveTaskInfoModalProps {
    color?: any;
    value?: any;
    setSelectedTaskType?: any;
    buttonName?: any;
    setButtonName?: any;
    options?: any;
}

function SaveTaskInfoModal({ color, value, setSelectedTaskType, buttonName, setButtonName, options }: SaveTaskInfoModalProps) {
    return (
        <Container>     
            <P>
             {formatMessage({ id: 'task.createTask', defaultMessage: 'Skapa aktivitet' })}
            </P>
        <div>
            <label
            htmlFor="custom-select"
            style={{
              textAlign: 'left',
              marginTop: '10px',
              marginBottom: '0.5rem',
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'grey',
              display: 'inline-block',
            }}
            >
              {formatMessage({ id: 'task.buttonTitle', defaultMessage: 'Button title' })}
            </label>
            <Input
                type="text"
                color={color}
                value={buttonName}
                onChange={(e: any) => setButtonName(e.target.value)}
                placeholder={formatMessage({ id: 'task.buttonText', defaultMessage: 'Button text' })}
            />
        </div>
            <CustomSelect
              label={formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}                 
              value={value}
              options={options}
              onChange={(selectedOption: any) =>
                  setSelectedTaskType(selectedOption)
              }
              placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
            /> 
        </Container>
    );
}

export default SaveTaskInfoModal