import React, { useEffect, useState } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { Button, FormikInput, FormikCheckbox } from '../../../../ui-kit'
import { LuTrash } from 'react-icons/lu'
import {
  FormPaper,
  Content,
  Title,
  Actions,
  Table,
  TableTitle,
} from './QuestionsForm.styles'
import { useCreateSurveyQuestionMutation, useUpdateSurveyQuestionMutation, useGetOneSurveyQuestionQuery } from '../../../../slices/surveyApiSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';  
import { SurveyQuestionChoiceTypeEnum } from '../../../../enum/Survey/QuestionsType'
import CustomSelect from '../../../../ui-kit/Dropdown/SurveyDropdown/SurveyDropdown'
import ImageUpload from '../../../../ui-kit/CustomFields/ImageUpload'

interface QuizFormProps {
  mode: 'create' | 'edit';
  surveyId: any;
  group?: any;
  questionId?: number;
  onChanged?: () => void;
  onResetMode?: () => void;
}

const QuizForm: React.FC<QuizFormProps> = ({ mode, surveyId, group, questionId, onChanged, onResetMode }) => {
  const { intl } = useIntlContext()
  const { permissions, userCompany } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const { data: questionData, refetch } = useGetOneSurveyQuestionQuery(
    { id: surveyId, questionId},
    { skip: !questionId },
  )
  const [createCourseQuestion] = useCreateSurveyQuestionMutation() 
  const [updateCourseQuestion] = useUpdateSurveyQuestionMutation()

  const [questionType, setQuestionType] = useState<any[]>([])

  useEffect(() => {
    const questionType: any = [
      { id: SurveyQuestionChoiceTypeEnum.DROPDOWN, title: formatMessage({ id: 'quiz.dropdown', defaultMessage: 'Dropdown' }) },
      { id: SurveyQuestionChoiceTypeEnum.STRING, title: formatMessage({ id: 'quiz.string', defaultMessage: 'String' }) },
      { id: SurveyQuestionChoiceTypeEnum.SCALE, title: formatMessage({ id: 'quiz.scale', defaultMessage: 'Scale' }) },
      { id: SurveyQuestionChoiceTypeEnum.BOOLEAN, title: formatMessage({ id: 'quiz.boolean', defaultMessage: 'Boolean' }) }
    ]
    setQuestionType(questionType)
  }, [intl])

  const initialValues = {
    question_text: '',
    image: null,
    choice_type: null,
    is_required: false,
    is_multiselect: false,
    page: 1,
    choices: [{ choice_text: '', image: null }],
  }

  useEffect(() => {
    if (mode === 'edit' && questionId) {
      refetch()
    }
  }, [mode, questionId ])

  const handleSubmit = async (values: any) => {
    try {
      const formattedValues = {
      question_text: values.question_text,
      choice_type: values.choice_type.value,
      image: values.image ? (values.image[0] || values.image) : '',
      is_required: values.is_required ? 1 : 0,
      comment_required: values.comment_required ? 1 : 0,
      group: values.group,
      ...(questionData?.data?.choice_type?.id && { choice_type_id: questionData?.data?.choice_type?.id }),
      is_multiselect: values.is_multiselect ? 1 : 0,
      choices: values.choices
        .filter((choice: any) => choice.choice_text.trim() !== '')
        .map(({ is_correct, ...choice }: any) => ({
        ...choice,
        survey_id: surveyId,
        image: choice.image ? (choice.image[0] || choice.image) : '',
        }))
      };


      if (formattedValues.image && typeof formattedValues.image === 'object' && 'url' in formattedValues.image && 'name' in formattedValues.image) {       
        delete formattedValues.image;
      }


      formattedValues.choices.forEach((choice: any) => {
          delete choice.image;
      });

      if (mode === 'create') {
        await createCourseQuestion({ id: surveyId, data: formattedValues }).unwrap();
      } else if (mode === 'edit') {
        await updateCourseQuestion({ id: surveyId, questionId, data: formattedValues }).unwrap();
      }

      onChanged?.();
      onResetMode?.();
    } catch (error) {
      console.error('Failed to create/update question:', error);
    }
  }

  const formikInitialValues = mode === 'edit' && questionData ? {
    ...questionData.data,
    choice_type: { value: questionData?.data?.choice_type?.field_type, label: questionType.find((type) => type.id === questionData?.data?.choice_type?.field_type)?.title },
    is_multiselect: questionData?.data?.choice_type?.is_multiple_choice,
    is_required: questionData?.data?.is_required,
    comment_required: questionData?.data?.comment_required,
    group: group,
    ...(questionData?.data?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.SCALE &&
      { scale_type: questionData?.data?.choices?.length === 11 ? { value: '0-10', label: '0-10' } : questionData?.data?.choices?.length === 6 ? { value: '0-5', label: '0-5' } : questionData?.data?.choices?.length === 5 ? { value: '1-5', label: '1-5' } : { value: '1-4', label: '1-4' } }),
    choices: questionData?.data?.choices || [{ choice_text: '', image: null }]
  } : initialValues

  const scaleOptions = [
    { value: '0-10', label: '0-10' },
    { value: '0-5', label: '0-5' },
    { value: '1-5', label: '1-5' },
    { value: '1-4', label: '1-4' },  
  ];

  const handleScaleChange = (setFieldValue: any, option: any, currentChoices: any) => {
    setFieldValue('scale_type', option);
    let choices: any = [];
  
    if (option.value === '0-10') {
      choices = Array.from({ length: 11 }, (_, i) => {
        const existingChoice = currentChoices[i];
        return existingChoice ? { ...existingChoice, choice_text: `${i}` } : { choice_text: `${i}`, image: null };
      });
    } else if (option.value === '0-5') {
      choices = Array.from({ length: 6 }, (_, i) => {
        const existingChoice = currentChoices[i];
        return existingChoice ? { ...existingChoice, choice_text: `${i}` } : { choice_text: `${i}`, image: null };
      });
    } else if (option.value === '1-5') {
      choices = Array.from({ length: 5 }, (_, i) => {
        const existingChoice = currentChoices[i];
        return existingChoice ? { ...existingChoice, choice_text: `${i + 1}` } : { choice_text: `${i + 1}`, image: null };
      });
    } else if (option.value === '1-4') {
      choices = Array.from({ length: 4 }, (_, i) => {
        const existingChoice = currentChoices[i];
        return existingChoice ? { ...existingChoice, choice_text: `${i === 0 ? 'Stämmer inte' : i === 1 ? 'Stämmer ibland' : i === 2 ? 'Stämmer oftast' : 'Stämmer helt'}` } :
          { choice_text: `${i === 0 ? 'Stämmer inte ' : i === 1 ? 'Stämmer ibland' : i === 2 ? 'Stämmer oftast' : 'Stämmer helt'}`, image: null };
      });
    }
  
    setFieldValue('choices', choices);
  };

  return (
    <FormPaper>
      <Formik initialValues={formikInitialValues}
        onSubmit={handleSubmit}
        enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <Title>
            {mode === 'create' ? 
            formatMessage({ id: 'quiz.createQestion', defaultMessage: 'Create question' }) : 
            formatMessage({ id: 'quiz.editQestion', defaultMessage: 'Edit question' })
            }
            </Title>
            <Content>
              <FormikInput
                label={formatMessage({ id: 'quiz.qestionLabel', defaultMessage: 'Question' })}
                name="question_text"
                placeholder={formatMessage({ id: 'quiz.qestionPlaceholder', defaultMessage: 'Type question here' })}
                autoComplete="off"
                required
              />
              <CustomSelect
                label={formatMessage({ id: 'quiz.questionType', defaultMessage: 'Question type' })}
                value={values.choice_type}
                onChange={(option) => {
                  setFieldValue('choice_type', option)
                }}
                options={questionType.map((type) => ({ value: type.id, label: type.title }))}
                placeholder={formatMessage({ id: 'quiz.selectQuestionType', defaultMessage: 'Select question type' })}               
                disabled={!canDo('survey: update')}
                marginTop={0}
              />

              {values.choice_type?.value === SurveyQuestionChoiceTypeEnum.SCALE && (
                <CustomSelect
                  label={formatMessage({ id: 'quiz.scaleType', defaultMessage: 'Scale type' })}
                  value={values.scale_type}
                  onChange={(option) => {
                    handleScaleChange(setFieldValue, option, values.choices);
                  }}
                  options={scaleOptions}
                  placeholder={formatMessage({ id: 'quiz.selectScaleType', defaultMessage: 'Select scale type' })}
                  disabled={!canDo('survey: update')}
                  marginTop={0}
                />
              )}
              <div style={{ border: '1px dashed #ccc', borderRadius: 8, width: '100%', height: '3rem', display: 'flex',  cursor: 'pointer', position: 'relative', marginTop: 10 }}>
             <ImageUpload
                  value={values.image}
                  onChange={(e: any) => {
                    setFieldValue(`image`, e.currentTarget.files);
                  }}
                  onRemove={() => {
                    setFieldValue(`image`, null);
                  }}  
                  mainColor={userCompany?.main_color}
                /> 
              </div>

              <FormikInput
                name="group"
                label={formatMessage({ id: 'quiz.page', defaultMessage: 'Page' })}
                placeholder={formatMessage({ id: 'quiz.pagePlaceholder', defaultMessage: 'Type page here' })}
                autoComplete="off"
              />
              <div style={{ display: 'flex', flexDirection: 'row', gap: 20, padding: 10 }}>
                <FormikCheckbox
                  name="is_required"
                  label={formatMessage({ id: 'quiz.is_required', defaultMessage: 'Is required' })}
                  disabled={!canDo('survey: update')}
                />
              {values.choice_type?.value === SurveyQuestionChoiceTypeEnum.DROPDOWN && (
                <FormikCheckbox
                name="is_multiselect"
                label={formatMessage({ id: 'quiz.is_multi', defaultMessage: 'Multiselect' })}
                disabled={!canDo('survey: update')}
                />)}
                <FormikCheckbox
                name="comment_required"
                label={formatMessage({ id: 'quiz.comment', defaultMessage: 'Add comment' })}
                disabled={!canDo('survey: update')}
                />
              </div>
              {(values.choice_type?.value === SurveyQuestionChoiceTypeEnum.BOOLEAN ||
                values.choice_type?.value === SurveyQuestionChoiceTypeEnum.DROPDOWN) && (
                  <FieldArray name="choices">
                {({ remove, push }) => (
                  <div style={{ marginTop: '1rem' }}> 
                    <Table>
                      <thead>
                      <tr>
                            <th>{formatMessage({ id: 'quiz.choice', defaultMessage: 'Choice' })}</th>
                       {/*      <th>{formatMessage({ id: 'quiz.image', defaultMessage: 'Image' })}</th>  */}
                        {canDo('survey: update') && (
                            <th style={{ width: '20px' }}>{formatMessage({ id: 'quiz.actions', defaultMessage: 'Actions'})} </th>
                            )}
                            
                      </tr>
                      </thead>
                      <tbody>
                          {values.choices.map((choice: any, index: any) => (
                        <tr key={index}>                       
                            <td style={{ padding: 15 }}>
                            <FormikInput
                              name={`choices.${index}.choice_text`}
                              label={`${formatMessage({id: 'quiz.choice', defaultMessage: 'Choice'})} ${index + 1}`}
                              placeholder={formatMessage({id: 'quiz.choicePlaceholder', defaultMessage: 'Type choice here'})}
                              autoComplete="off"
                              required
                            />
                              </td>
               {/*              <td>
                                <ImageUpload
                                  value={values.choices[index].image}
                                  onChange={(e: any) => {
                                    setFieldValue(`choices.${index}.image`, e.currentTarget.files);
                                  }}
                                  onRemove={() => {
                                    setFieldValue(`choices.${index}.image`, null);
                                  }}  
                                />
                              </td>  */}
                          {canDo('survey: update') && (
                            <td style={{ textAlign: 'center' }}>
                                  <Button type="button"
                                    onClick={() => {
                                      remove(index)
                                    }}>
                                <LuTrash size={16} />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                      {canDo('survey: update') && (
                    <>
                      {values?.choices?.length > 1 && values.choice_type?.value === SurveyQuestionChoiceTypeEnum.BOOLEAN ? (
                       <></>
                          ) : (
                          <>
                          <Button
                          type="button"
                          appearance="secondary"
                          onClick={() => push({ choice_text: '', is_correct: false })}
                          style={{ marginTop: '1rem' }}
                        >
                          {formatMessage({ id: 'quiz.newChoice', defaultMessage: 'New choice' })}
                        </Button>
                          </>  
                      )}  
                    </>
                    )}
                  </div>
                )}
              </FieldArray>
              )}
              
            </Content>
            {canDo('survey: update') && (
              <Actions>
                <Button appearance="primary" type="submit">
                {mode === 'create' ? 
                formatMessage({id: 'quiz.create', defaultMessage: 'Create'}) : 
                formatMessage({id: 'quiz.save', defaultMessage: 'Save'})
                }
                </Button>
              </Actions>
            )}
          </Form>
        )}
      </Formik>
    </FormPaper>
  )
}

export default QuizForm
