import React, { useState } from 'react';
import { MainDiv } from './ModalContentView.style';
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { CustomFieldPermisson } from '../../../../../enum/TaskPermisson/CustomFieldPermisson';
import  CheckBox  from '../../checkbox/CheckBox';
import { Button } from '../../../../../ui-kit';
import { formatMessage } from '../../../../../locale';

interface TaskType {
  taskTypes?: any
  selectedType?: any
  setSelectedType?: any
  nameTypeEn?: any
  setNameTypeEn?: any,
  nameTypeSv?: any,
  setNameTypeSv?: any,
  optionsDropdown?: any
  isInput?: any
  setIsInput?: any
  isOutput?: any
  setIsOutput?: any
  isRequired?: any
  setIsRequired?: any
  isMulti?: any
  setIsMulti?: any
}

function ModalContent({ selectedType, nameTypeEn, nameTypeSv, optionsDropdown,
  isInput, isOutput, isRequired, isMulti, setIsMulti
 }: TaskType) {

  const [selectedDropdown, setSelectedDropdown] = useState<boolean>(false);
  const [selectedDropdown2, setSelectedDropdown2] = useState<boolean>(false);

  const [sect, setSelct] = useState<any>(true);

  const isDescriptionRequired = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
    ].includes(type?.value);
  };

  const isMultiselect = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
      CustomFieldPermisson.DEPARTMENT,
      CustomFieldPermisson.PROJECT_GROUP,
      CustomFieldPermisson.JOB_TITLE,
      CustomFieldPermisson.OFFICE,
      CustomFieldPermisson.USER,
    ].includes(type?.value);
  };

  return (
    <MainDiv>
      <h2 style={{width: 300, marginBottom: 20}}>{selectedType.label}</h2>
      <Button
        appearance='secondary'
            onClick={() => setSelectedDropdown(!selectedDropdown)}
            style={{
              fontSize: 16,
              width: 300,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: '2px solid',
              borderRadius: 8,
              borderBottomLeftRadius: selectedDropdown ? 0 : 8,
              borderBottomRightRadius: selectedDropdown ? 0 : 8,
              borderBottom: selectedDropdown ? 'none' : '1.5px solid lightgrey',
              borderColor: 'lightgrey',
              color: 'grey',
            }}
          >
            {formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} 
            {selectedDropdown ? (
              <RiArrowDropUpLine size={34} />
            ) : (
              <RiArrowDropDownLine size={34} />
            )}
          </Button>
          {selectedDropdown && (
            <div
              style={{
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                border: '2px solid lightgrey',
                width: 300,
                maxWidth: 300,
                textAlign: 'left',
              }}
            >
              <ul style={{ marginBottom: '0.5rem', textAlign: 'left', padding: '0 0 0 30px' }}>
                <li style={{padding: '10px 10px 10px 0', border: 10, listStyleType: 'initial', color: 'grey'}}>En: {nameTypeEn}</li>
                <li style={{padding: '10px 10px 10px 0', border: 10, listStyleType: 'initial', color: 'grey'}}>Sv: {nameTypeSv}</li>
              </ul>
            </div>
          )}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}></div>
      
      {selectedType && isDescriptionRequired(selectedType) && (
        <>
          <Button
            appearance='secondary'
            onClick={() => setSelectedDropdown2(!selectedDropdown2)}
            style={{
              fontSize: 16,
              width: 300,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: '2px solid',
              borderRadius: 8,
              borderBottomLeftRadius: selectedDropdown2 ? 0 : 8,
              borderBottomRightRadius: selectedDropdown2 ? 0 : 8,
              borderBottom: selectedDropdown2 ? 'none' : '1.5px solid lightgrey',
              borderColor: 'lightgrey',
              color: 'grey',
            }}
          >
            {formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })}
            {selectedDropdown2 ? (
              <RiArrowDropUpLine size={34} />
            ) : (
              <RiArrowDropDownLine size={34} />
            )}
      </Button>
      
      {selectedDropdown2 && (
            <div
              style={{
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                border: '2px solid lightgrey',
                width: 300,
                maxWidth: 300,
                textAlign: 'left',
              }}
            >
              
                {optionsDropdown?.map((option: any, index: number) => (
                <ul style={{ marginBottom: '0.5rem', textAlign: 'left', padding: '0 0 0 30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <li key={index} style={{padding: '10px 10px 10px 0', border: 10, color: 'grey'}}>
                    En: {option.find((item: any) => item.language_code === 'en')?.translated_value}
                  </li>
                  <li style={{ padding: '10px 10px 10px 0', border: 10, color: 'grey' }}>
                    Sv: {option.find((item: any) => item.language_code === 'en')?.translated_value}
                  </li>
                </ul>
                ))}
            </div>
      )}
      </>
        )}
      <CheckBox
        isChecked={isInput}
        onChange={setSelct}
        label={formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}
        />

      <CheckBox 
        isChecked={isOutput}
        onChange={setSelct}
        label={formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}
      />

    {selectedType && isMultiselect(selectedType) && (
      <CheckBox 
        isChecked={isMulti}
        onChange={setSelct}
        label={formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is multiselect' })}
      />
    )}	
      <CheckBox
        isChecked={isRequired}
        onChange={setSelct}
        label={formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}
      />
    </MainDiv>
  );
}

export default ModalContent;