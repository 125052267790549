import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import {
  StyledAccordion,
  StyledAccordionBody,
  StyledAccordionHeading,
  StyledAccordionItem,
  StyledContent,
  StyledToggle,
} from './Accordion.styles'
import { MdKeyboardArrowDown } from "react-icons/md";

interface AccordionContentProps {
  id: string
  header: string
  content: ReactNode | string
}
interface AccordionProps {
  data: AccordionContentProps[]
  heading?: string
}

interface AccordionItemProps {
  active: string | null
  handleToggle: any
  item: AccordionContentProps
  index: number
}

const AccordionItem = ({
  active,
  handleToggle,
  item,
  index,
}: AccordionItemProps) => {
  const contentRef = useRef(null)
  const { header, id, content } = item
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    if (contentRef.current) {
      const contentElem = contentRef.current as HTMLElement

      setHeight(active === id ? contentElem.scrollHeight : 0)
    }
  }, [active, id])

  return (
    <StyledAccordionItem>
      <StyledToggle
        onClick={() => handleToggle(id)}
        active={active === id}
        role="button"
        data-testid={`toggle-${index}`}
      >
        <div>{header}</div>
       <MdKeyboardArrowDown />
      </StyledToggle>
      <StyledContent ref={contentRef} style={{ height: height }}>
        <StyledAccordionBody data-testid="accordionItem">
          {content}
        </StyledAccordionBody>
      </StyledContent>
    </StyledAccordionItem>
  )
}

const Accordion = ({ data, heading }: AccordionProps) => {
  const [active, setActive] = useState<string | null>(null)

  const handleToggle = (index: string) => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  return (
    <StyledAccordion>
      {heading && (
        <StyledAccordionHeading data-testid="accordionHeading">
          {heading}
        </StyledAccordionHeading>
      )}

      {data.map((item, index) => (
        <AccordionItem
          key={index}
          active={active}
          handleToggle={handleToggle}
          item={item}
          index={index}
        />
      ))}
    </StyledAccordion>
  )
}

export default Accordion
