import styled from 'styled-components'
import { StyledPaper } from '../../ui-kit/Paper/Paper.styles'

export const FormPaper = styled(StyledPaper)`
  padding: 0;
  overflow: hidden;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`
export const Search = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const SubPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
  border-bottom: 1px solid rgb(210 210 210 / 50%);
  padding: 0 1rem;
  height: 5.063rem;
`

export const Title = styled.h2`
  font-size: 1.8rem;
`

export const TableContainer = styled.div`
  padding: 2rem 1rem 2rem;
`

export const ResponsiveContainer = styled.div`
  flex: wrap;
  border: 1px solid rgba(234, 231, 242, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const ResponsiveHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ResponsiveFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
