import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import List from './components/List/List';
import { Button } from './../../../ui-kit';
import { useGetSurveyFormQuery } from '../../../slices/surveyFormApiSlice';

const PublicSurveyFormContainer = styled.div`
    height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 20px;
    background-color: rgb(249, 246, 248);
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    
    
`;

const Title = styled.h1`
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word; 
    max-width: 100%; 

`;

const Description = styled.p`
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%; 

    @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`;

const Image = styled.img`
    width: 60%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StartButton = styled(Button)`
    width: 100px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const PublicSurveyForm: React.FC = () => {
    const id = localStorage.getItem('hashID');

    const { data: surveyData, error, isLoading } = useGetSurveyFormQuery({ id: id });

    const [start, setStart] = useState(false);



    useEffect(() => {
        if (error) {
          let timeLeft = 10;
          const countdownElement = document.getElementById("countdown");
      
          const countdownInterval = setInterval(() => {
            timeLeft--;
            if (countdownElement) {
              countdownElement.textContent = timeLeft.toString();
            }
            
            if (timeLeft <= 0) {
              clearInterval(countdownInterval);
              localStorage.removeItem('hashID'); 
            }
          }, 2000);
        }
      }, [error]); 
    
    if (error) {
        return (
            <PublicSurveyFormContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>{ (error as any)?.data?.message || 'Ops, something went wrong'}</h1>     
            </PublicSurveyFormContainer>
        );
    }

    return (   
        <PublicSurveyFormContainer>
            {!start &&
                <ContentContainer>
                    <Title>{surveyData?.data?.title}</Title>
                    <Description>{surveyData?.data?.description}</Description>
                    {surveyData?.data?.image?.url && <Image src={surveyData?.data?.image?.url} />}
                    <StartButton onClick={() => { setStart(true) }}>START</StartButton>
                </ContentContainer>}
            {start && <List data={surveyData?.data} id={id} />}
        </PublicSurveyFormContainer>
    );
};

export default PublicSurveyForm;
