import React, { useState } from 'react';
import { CardGrid } from './Card/Card';
import PaginableList from '../../../ui-kit/PaginableListRisk';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import { useGetTaskRisksQuery } from '../../../slices/taskApiSlice';

export const RiskList: React.FC = () => {
  const { intl } = useIntlContext();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList
      query={useGetTaskRisksQuery}
      currentPage={currentPage}
      >
      {(risks, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>

        const cardsData = risks.map((risk: any) => {
          const customField = risk?.custom_fields.find((field: any) => field.field_type === 'risk');
          return {
            id: risk?.id,
            title: risk?.title,
            taskType: risk?.task_type?.name,
            assignee: risk?.assignee?.fullname,
            custom_field_list_values: risk?.custom_field_list_values,
            custom_field: customField?.risk_items,
            created_at: risk?.created_at,
          };
        });

        return <>
         {cardsData.length > 0 ? ( 
          <CardGrid cards={cardsData} />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', width: '100%' }}>
              <h2 style={{ fontWeight: 'normal', color: 'grey' }}>
                {formatMessage({ id: 'dashboard.noRisk', defaultMessage: 'Select Risk in the dashboard settings to view the risks.' })}
              </h2>
            </div>
          )}
        </>;
      }}
    </PaginableList>
  );
};

export default RiskList;