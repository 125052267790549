import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { AiOutlineUpload } from 'react-icons/ai';
import { FaImage } from "react-icons/fa6";
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';

interface ImageUploadProps {
  value?: any;
  onChange?: any;
  onRemove?: any;
  label?: any;
  mainColor?: any;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ value, onChange, onRemove, label, mainColor }) => {
  const { intl } = useIntlContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', width: '100%', position: 'relative' }}>
        {value && (
          <div style={{ width: '100%', height: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button
              type="button"
              onClick={handleImageClick}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                position: 'absolute',
                color: mainColor,
                fontWeight: 'bold',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 7,
              }}
            >
              <FaImage size={20} />
              {formatMessage({ id: 'imageUpload.clickToViewImage', defaultMessage: 'Click to view image' })}
            </button>
            <button
              type="button"
              onClick={onRemove}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                position: 'absolute',
                right: 5,
                height: '100%',
                width: '3rem',
              }}
            >
              <IoIosClose size={28} />
            </button>
          </div>
        )}
      </div>
      {!value && (
        <label style={{ cursor: 'pointer', width: '100%', height: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 7 }}>
            <AiOutlineUpload size={25} color={mainColor} />
            <span>{formatMessage({ id: 'imageUpload.choosePhoto', defaultMessage: 'Select a photo' })}</span>
          </div>
          <input
            type="file"
            onChange={onChange}
            style={{
              position: 'absolute',
              width: '100%',
              height: '3rem',
              display: 'none'
            }}
          />
        </label>
      )}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10001,
          }}
          onClick={handleOutsideClick}
        >
          <div
            style={{
              position: 'relative',
              backgroundColor: 'white',
              padding: '1rem',
              borderRadius: '8px',
            }}
          >
            <img
              src={typeof value === 'string' ? value : value?.url || URL.createObjectURL(value[0])}
              alt="list_image"
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
            />
            <button
              type="button"
              onClick={handleCloseModal}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <IoIosClose size={45} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUpload;