import React, { useState, useEffect } from 'react';

import { ListContainer, ListItem, Actions, Button, Header } from './ProjectGroupList.styles';
import { LuFileEdit, LuTrash, LuPlus } from 'react-icons/lu';
import { ProjectGroupPopup } from './ProjectGroupPopup';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useDeleteProjectGroupMutation, useGetAllProjectGroupsSimpleQuery } from '../../../../../slices/projectGroupApiSlice'
import { useSelector } from 'react-redux'
import ButtonSecondary from '../../../../../ui-kit/Button/ui/components/ButtonSecondary'

interface ProjectGroupList {
  id: number;
  name: string;
}

export const ProjectGroupList: React.FC = () => {
  const { intl } = useIntlContext();
  const { data: projectGroupData, error, isLoading, refetch } = useGetAllProjectGroupsSimpleQuery({});
  const [deleteProjectGroup] = useDeleteProjectGroupMutation();

     const { permissions } = useSelector((state: any) => state.auth)
     const canDo = (permission: string) => {
       return permissions && !!permissions[permission]
     }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProjectGroup, setSelectedProjectGroup] = useState<ProjectGroupList| undefined>(undefined);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const handleAdd = () => {
    setSelectedProjectGroup(undefined);
    setMode('create');
    setIsPopupOpen(true);
  };

  const handleUpdate = (projectGroup: ProjectGroupList) => {
    setSelectedProjectGroup(projectGroup);
    setMode('edit');
    setIsPopupOpen(true);
  };

  const handleDelete = async (projectGroupId: number) => {
    try {
      await deleteProjectGroup({ id: projectGroupId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Error deleting office:', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedProjectGroup(undefined);
  };

  const handlePublish = () => {
    refetch();
    handleClosePopup();
  };
   
  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'groupProject.errorLoadingProjectGroup', defaultMessage: 'Error loading Project group list' })}</div>;
  
  return (
    <ListContainer>
    {(canDo('project group: view all') || canDo('project group: view paginated')) && (
      projectGroupData?.data?.map((jobTitle: ProjectGroupList) => (
        <ListItem key={jobTitle.id}>
          <span>{jobTitle.name}</span>
          <Actions>
            {canDo('project group: update') && (
              <Button onClick={() => handleUpdate(jobTitle)}><LuFileEdit size={16} /></Button>
            )}
            {canDo('project group: delete') && (
              <Button onClick={() => handleDelete(jobTitle.id)}><LuTrash size={16} /></Button>
            )}
          </Actions>
        </ListItem>
      ))
    )}
      {isPopupOpen && (
        <ProjectGroupPopup
         projectGroup={selectedProjectGroup}
          mode={mode}
          onClose={handleClosePopup}
          onPublish={handlePublish}
        />
      )}
      {canDo('project group: create') && (
        <Header style={{ marginTop: '20px' }}>
          <ButtonSecondary onClick={handleAdd}>
            <LuPlus size={20} />
            {formatMessage({ id: 'groupProject.newGroupButton', defaultMessage: 'New Project Group' })}
          </ButtonSecondary>
        </Header>)}
    </ListContainer>
  );
};



