import React from "react";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Dropdown from '../../../../ui-kit/Dropdown/TaskDropdown';
import { Formik, Form } from 'formik';
import {
    Content,
    Title,
    Actions,
} from './Reoccurrence.style';
import { Button, FormikCheckbox } from '../../../../ui-kit';
import { formatMessage } from '../../../../locale';
import { Reoccurrence as ReoccurrenceEnum, ReoccurrenceTranslations } from '../../../../enum/Survey/Reoccurrence';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { useUpdateSurveyReoccurrenceMutation } from '../../../../slices/surveyApiSlice';
import { useMedia } from "../../../../ui-kit/utils/media";

interface SurveyData {
    survey?: any;
    onChanged?: () => void;
}


const Reoccurrence = ({ survey, onChanged }: SurveyData) => {
      const { isMobile, isTablet, isWide } = useMedia();
      const { intl } = useIntlContext();
      
      const [updateReoccurrence] = useUpdateSurveyReoccurrenceMutation();
    
      const { permissions } = useSelector((state: RootState) => state.auth);
      const canDo = (permission: string) => {
        return permissions && !!permissions[permission]
    }
    const lang = intl.locale === 'en' ? 'en' : 'sv';
    const initialValues = {
        is_recurring: survey?.is_recurring  || false,
        recurrence_interval: survey?.recurrence_interval || '',
      };
    
    const dropdownOptions = Object.keys(ReoccurrenceEnum).map(key => ({
        value: ReoccurrenceEnum[key as keyof typeof ReoccurrenceEnum],
        label: ReoccurrenceTranslations[ReoccurrenceEnum[key as keyof typeof ReoccurrenceEnum]][lang]
    }));

    const getTranslatedValue = (value: string) => {
        const translation = ReoccurrenceTranslations[value as keyof typeof ReoccurrenceTranslations];
        return translation ? translation[lang] : '';
    };

    const handleSubmit = async (values: any) => {
        try {
            const data = {
                is_recurring: values.is_recurring ? 1 : 0,
                ...(values.is_recurring && { recurrence_interval: values.recurrence_interval })
            };
            await updateReoccurrence({ id: survey.id, data }).unwrap();
            onChanged?.()
        } catch (error) {
            console.error(error);
        }
    }

    return <>
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                 <Title>{formatMessage({ id: 'createEditSurvey.editReoccurrence', defaultMessage: 'Edit Participants' })}</Title>
                    <Content>
                    {values.is_recurring && (
                    <div style={{ width: isMobile ? '100%' : isTablet ? '60%': isWide ? '40%' : '22%', marginBottom: '20px' }}>    
                        <Dropdown
                            height="3rem"
                            label=''
                            value={values.recurrence_interval ? { value: values.recurrence_interval, label: getTranslatedValue(values.recurrence_interval) } : null}
                            onChange={(value: any) => {
                                setFieldValue('recurrence_interval', value.value);
                            }}
                            options={dropdownOptions}
                            placeholder={formatMessage({ id: 'createEditSurvey.recurrence_interval', defaultMessage: 'Recurrence interval' })}
                        />
                    </div>
                    )}
                        <FormikCheckbox
                         label={formatMessage({ id: 'createEditSurvey.reoccurrenceLabel', defaultMessage: 'Recurring survey' })}
                         name="is_recurring"
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           const isChecked = e;
                             setFieldValue('is_recurring', isChecked);    
                             if (isChecked) {
                                setFieldValue('recurrence_interval', '');
                           }  
                         }}
                        />     
                    </Content>          
                    {canDo('survey: create') && (
                        <Actions>
                          <Button appearance="primary" type="submit">
                            {formatMessage({ id: 'schedule.saveChanges', defaultMessage: 'Save Changes' })}
                          </Button>
                        </Actions>
                    )}
                </Form>
            )}
            
        </Formik>
    </>
}

export default Reoccurrence

