export enum DROPDOW_OPTIONS {
    USER = 'user',
    DEPARTMENT = 'department',
    OFFICE = 'office',
    JOB_TITLE = 'job-title',
    PROJECT_GROUP = 'project-group'
}

export enum INPUT_FILED {
    STRING = 'string',
    NUMBER = 'number',
}

export enum DATE_OPTIONS {
    DATE = 'date',
}

export enum TASK_FORM_OPTIONS {
    DROPDOWN = 'dropdown',
} 

export enum EDITOR {
    EDITOR = 'editor',
}
export enum LIST {
    LIST = 'lists',
}
export enum IMAGES {
    IMAGES = 'image',
}

export enum RISK {
    RISK = 'risk',
}