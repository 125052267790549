import React, { useState, useEffect } from 'react';

import { ListContainer, ListItem, Actions, Button, Header } from './JobTitleList.styles';
import { LuFileEdit, LuTrash, LuPlus } from 'react-icons/lu';
import { JobTitlePopup } from './JobTitlePopup';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useDeleteJobTitleMutation, useGetAllJobTitlesSimpleQuery } from '../../../../../slices/jobTitleApiSlice'
import { useSelector } from 'react-redux'
import ButtonSecondary from '../../../../../ui-kit/Button/ui/components/ButtonSecondary'

interface JobTitleList {
  id: number;
  name: string;
}

export const JobTitleList: React.FC = () => {
  const { intl } = useIntlContext();
  const { data: jobTitleData, error, isLoading, refetch } = useGetAllJobTitlesSimpleQuery({});
  const [deleteJobTitle] = useDeleteJobTitleMutation();

   const { permissions } = useSelector((state: any) => state.auth)
   const canDo = (permission: string) => {
     return permissions && !!permissions[permission]
   }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedJobTitle, setJobTitle] = useState<JobTitleList| undefined>(undefined);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const handleAdd = () => {
    setJobTitle(undefined);
    setMode('create');
    setIsPopupOpen(true);
  };

  const handleUpdate = (jobTitleData: JobTitleList) => {
    setJobTitle(jobTitleData);
    setMode('edit');
    setIsPopupOpen(true);
  };

  const handleDelete = async (jobTitleDataId: number) => {
    try {
      await deleteJobTitle({ id: jobTitleDataId }).unwrap();
      refetch();
    } catch (error) {
      console.error('Error deleting jobTitle:', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setJobTitle(undefined);
  };

  const handlePublish = () => {
    refetch();
    handleClosePopup();
  };

  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'jobTitle.errorLoadingJobTitles', defaultMessage: 'Error loading jobTitles' })}</div>;
  
  return (
    <ListContainer>
    {(canDo('job title: view all') || canDo('job title: view paginated')) && (
      jobTitleData?.data?.map((jobTitle: JobTitleList) => (
        <ListItem key={jobTitle.id}>
          <span>{jobTitle.name}</span>
          <Actions>
            {canDo('job title: update') && (
              <Button onClick={() => handleUpdate(jobTitle)}><LuFileEdit size={16} /></Button>
            )}
            {canDo('job title: delete') && (
              <Button onClick={() => handleDelete(jobTitle.id)}><LuTrash size={16} /></Button>
            )}
          </Actions>
        </ListItem>
      ))
    )}
      {isPopupOpen && (
        <JobTitlePopup
          jobTitle={selectedJobTitle}
          mode={mode}
          onClose={handleClosePopup}
          onPublish={handlePublish}
        />
      )}
      {canDo('job title: create') && (
        <Header style={{marginTop: '20px'}}>
        <ButtonSecondary onClick={handleAdd}>
          <LuPlus size={20} />
          {formatMessage({ id: 'jobTitle.newJobTitleButton', defaultMessage: 'New Job Title' })}
        </ButtonSecondary>
      </Header>)}
    </ListContainer>
  );
};
