import { formatMessage } from '../../locale'
import { TaskStatus } from '../../enum/Task/TaskStatus'


export const translateStatusName = (name: string) => {
  switch (name) {
    case 'planned':
    case 'planerad':
    case TaskStatus.PLANNED:
      return formatMessage({ id: 'taskStatus.planned', defaultMessage: 'planned' });
    case 'ongoing':
    case 'pågående':
    case TaskStatus.ONGOING:
      return formatMessage({ id: 'taskStatus.ongoing', defaultMessage: 'ongoing' });
    case 'ongoing evaluation':
    case 'pågående utvärdering':
    case TaskStatus.ONGOING_EVALUATION:
      return formatMessage({ id: 'taskStatus.ongoingEvaluation', defaultMessage: 'ongoing evaluation' });
    case 'overdue':
    case 'förfallen':
    case TaskStatus.OVERDUE:
      return formatMessage({ id: 'taskStatus.overdue', defaultMessage: 'overdue' });
    case 'not completed':
    case 'ej slutförd':
    case TaskStatus.NOT_COMPLETED:
      return formatMessage({ id: 'taskStatus.notCompleted', defaultMessage: 'not completed' });
    case 'completed':
    case 'slutförd':
    case TaskStatus.COMPLETED:
      return formatMessage({ id: 'taskStatus.completed', defaultMessage: 'completed' });
    case 'completed without evaluation':
    case 'slutförd med utvärdering':
    case TaskStatus.COMPLETED_WITH_EVALUATION:
      return formatMessage({ id: 'taskStatus.completedWithEvaluation', defaultMessage: 'completed without evaluation' });
    case 'completed without evaluation':
    case 'slutförd utan utvärdering':
    case TaskStatus.COMPLETED_WITHOUT_EVALUATION:
      return formatMessage({ id: 'taskStatus.completedWithoutEvaluation', defaultMessage: 'COMPLETED WITHOUT EVALUATION' });
    case 'evaluation':
    case 'utvärdering':
    case TaskStatus.EVALUATION_POSSIBLE:
      return formatMessage({ id: 'taskStatus.evaluationPossible', defaultMessage: 'evaluation' });
    case 'cancelled':
    case 'avbryten':
    case TaskStatus.CANCELLED:
      return formatMessage({ id: 'taskStatus.cancelled', defaultMessage: 'cancelled' });
    default:
      return name;
  }
};