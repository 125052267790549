import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { FormikInput, FormikTextarea, Button } from '../../../../../ui-kit'
import CustomSelect from '../../../../../ui-kit/Dropdown/SurveyDropdown/SurveyPublicForm/SurveyDropdown'
import CustomSelectMulti from '../../../../../ui-kit/Dropdown/SurveyDropdown/SurveyPublicForm/SurveyDropdownMulti'
import {
  FormPaper, DataContainer, Row,
  QuestionText, QuestionContent, ScaleOneToTenContainer,
  QuestionImage, CenteredContainer, CommentWidth,
  SmilieContainer, StyledChoiceButton, RequiredIndicatorContainer,
  RequiredIndicatorText, RowGroup, ContainerGroup, QuestionSubText,
  Tr, TdText, Table, Thead, Th, Tbody
} from './List.style'
import { SurveyQuestionChoiceTypeEnum } from '../../../../../enum/Survey/QuestionsType'
import { useSubmitSurveyFormMutation } from '../../../../../slices/surveyFormApiSlice'
import { FaRegStar, FaStar } from "react-icons/fa";
import {
  FaRegFaceAngry, FaFaceAngry, FaRegFaceFrown, FaFaceFrown,
  FaFaceMeh, FaRegFaceMeh, FaRegFaceSmile, FaFaceSmile, FaFaceSmileBeam, FaRegFaceSmileBeam,  FaRegFaceGrinStars, FaFaceGrinStars
} from "react-icons/fa6";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import { RiInformationLine } from "react-icons/ri";
import { GoCheckCircle } from "react-icons/go";
import ToastError from '../Toast/ToastSurveyError'
import Toaltip from '../../../../../ui-kit/ToolTip/TooltipContainer'
import { useMedia } from '../../../../../ui-kit/utils/media' 

function List({ id, data }: any) {
  const { isMobile, isTablet, isWide } = useMedia()
  const [currentPage, setCurrentPage] = useState(0);
  const [hoveredStars, setHoveredStars] = useState<{ [key: number]: number | null }>({});
  const [selectedStars, setSelectedStars] = useState<{ [key: number]: number }>({});
  const [completed, setCompleted] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState('')
  const [expanded, setExpanded] = useState(false);

  const [submitSurveyForm] = useSubmitSurveyFormMutation()

  const initialValues = {
    questions: data?.questions.reduce((acc: any, field: any) => {
      switch (field?.choice_type?.field_type) {
        case SurveyQuestionChoiceTypeEnum.DROPDOWN:
        case SurveyQuestionChoiceTypeEnum.SCALE:
        case SurveyQuestionChoiceTypeEnum.BOOLEAN:
          acc[field?.id] = null
          break
        case SurveyQuestionChoiceTypeEnum.STRING:
        case SurveyQuestionChoiceTypeEnum.NUMBER:
        case SurveyQuestionChoiceTypeEnum.DATE:
          acc[field?.id] = ''
          break
        default:
          return acc
      }
      return acc
    }, {}),
    comments: data?.questions.reduce((acc: any, field: any) => {
      acc[field?.id] = ''
      return acc
    }, {})
  }

  const handleSubmit = async (values: any) => {
    const answers: any = {answers: []}

    Object.keys(values.questions).forEach((key) => {
      if (!values.questions[key]) {
        delete values.questions[key]
      }
      if (!isNaN(Number(key))) {
        const question = data?.questions?.map((question: any) => question?.questions).flat().find((question: any) => question?.id === Number(key))
         if (question && values.questions[key] && values?.questions[key]?.value !== '') {
           if (question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.DROPDOWN) {
            answers.answers.push({
              question_id: Number(key),
              choice_type: question?.choice_type?.field_type,
              choices: Array.isArray(values.questions[key])
                ? values.questions[key].map((choice: any) => ({ id: choice?.value }))
                : [{ id: values.questions[key]?.value }],
              comment: values.comments[key]
            })
          } else if (question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.STRING) {
            answers.answers.push({
              question_id: Number(key),
              choice_type: question?.choice_type?.field_type,
              choices: [values.questions[key]],
              comment: values.comments[key]
            })
           } else if (question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.BOOLEAN) {
            answers.answers.push({
              question_id: Number(key),
              choice_type: question?.choice_type?.field_type,
              choices: [{ id: Array.isArray(values.questions[key]) ? values.questions[key]?.map((choice: any) => choice?.value) : values.questions[key]}],
              comment: values.comments[key]
            })
           } else if (question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.SCALE) {
               answers.answers.push({
                 question_id: Number(key),
                 choice_type: question?.choice_type?.field_type,
                 choices: [{ id: Array.isArray(values.questions[key]) ? values.questions[key]?.map((choice: any) => choice?.value) : values.questions[key]}],
                 comment: values.comments[key]
               })
          }
        } 
      }
    })

    try {
       await submitSurveyForm({id: id, data: answers }).unwrap()    
       setCompleted(true)
    
    } catch (error) {
      console.error('Error submitting survey form:', error)
      setToastError(true)
      setToastErrorMessage((error as any)?.data?.message || 'Error submitting survey form') 
      console.log('Error submitting survey form:', )
    }
  }

  const handleNextPage = () => {
    if (currentPage < data.questions.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleStarClick = (setFieldValue: any, questionId: number, choiceId: any, starIndex: number) => {
    setSelectedStars((prev) => ({ ...prev, [questionId]: starIndex }));
    setHoveredStars((prev) => ({ ...prev, [questionId]: null })); 
    setFieldValue(`questions.${questionId}`, choiceId);
  };

  const handleStarMouseEnter = (questionId: number, starIndex: number) => {
    setHoveredStars((prev) => ({ ...prev, [questionId]: starIndex }));
  };

  const handleStarMouseLeave = (questionId: number) => {
    setHoveredStars((prev) => ({ ...prev, [questionId]: null }));
  };

  const renderIcon = (choiceIndex: number, isFilled: boolean) => {
    switch (choiceIndex) {
      case 0:
        return isFilled ? <FaFaceAngry color="#d63a3a" size={isMobile ? 25 : 35} /> : <FaRegFaceAngry color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      case 1:
        return isFilled ? <FaFaceFrown color="#d6883a" size={isMobile ? 25 : 35} /> : <FaRegFaceFrown color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      case 2:
        return isFilled ? <FaFaceMeh color="#ecce00" size={isMobile ? 25 : 35} /> : <FaRegFaceMeh color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      case 3:
        return isFilled ? <FaFaceSmile color="rgb(39, 177, 145)" size={isMobile ? 25 : 35} /> : <FaRegFaceSmile color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      case 4:
        return isFilled ? <FaFaceSmileBeam color="#007c3e" size={isMobile ? 25 : 35} /> : <FaRegFaceSmileBeam color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      case 5:
        return isFilled ? <FaFaceGrinStars color="#168484"  size={isMobile ? 25 : 35} /> : <FaRegFaceGrinStars color="rgb(179, 177, 177)" size={isMobile ? 25 : 35} />;
      default:
        return null;
    }
  };

  const totalGroups = data?.questions.length;
  const currentGroup = currentPage + 1;


    useEffect(() => {
      if (toastError) {
        const timer = setTimeout(() => {
          setToastError(false);
        }, expanded ? 4500 : 4500);
    
        return () => clearTimeout(timer); 
      }
    }, [toastError, expanded]);


  useEffect(() => {
    if (completed) {
      let timeLeft = 10;
      const countdownElement = document.getElementById("countdown");
  
      const countdownInterval = setInterval(() => {
        timeLeft--;
        if (countdownElement) {
          countdownElement.textContent = timeLeft.toString();
        }
        
        if (timeLeft <= 0) {
          clearInterval(countdownInterval);
          localStorage.removeItem('hashID');
        }
      }, 2000);
    }
  }, [completed]);


  return (
      <FormPaper>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => { }}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
          <Form>   
            <span style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 10, marginRight: 10 }}>{currentGroup} of {totalGroups}</span>
              <div style={{ display: 'flex', flex: 1, height: '10px',  borderRadius: '5px', overflow: 'hidden' }}>
                {Array.from({ length: totalGroups }).map((_, index) => (
                  <div
                  key={index}
                  style={{
                    flex: 1,
                    backgroundColor: index < currentGroup ? 'rgba(170, 17, 103, 1)' : '#e0e0e0',
                    marginRight: index < totalGroups - 1 ? '3px' : '0',
                    borderRadius: index < currentGroup ? '5px' : '5px',
                  }}
                  />
                ))}
              </div>
            <DataContainer>
                {data && data.questions[currentPage].questions &&

                  data.questions[currentPage].questions.map((question: any, index: number) => {
                    const prevQuestion = data.questions[currentPage].questions[index - 1];
                    const nextQuestion = data.questions[currentPage].questions[index + 1];
                    const isSameAsPrev = prevQuestion && prevQuestion.choice_type.field_type === question.choice_type.field_type && (
                      (question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.SCALE && prevQuestion.choices?.length === question.choices?.length) ||
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.STRING ||
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.BOOLEAN || 
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.DROPDOWN
                    );
                    const isSameAsNext = nextQuestion && nextQuestion.choice_type.field_type === question.choice_type.field_type && (
                      (question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.SCALE && nextQuestion.choices?.length === question.choices?.length) ||
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.STRING ||
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.BOOLEAN ||
                      question.choice_type.field_type === SurveyQuestionChoiceTypeEnum.DROPDOWN
                    );

                    const shouldGroup = isSameAsPrev || isSameAsNext;
                  return (
                    <div key={question.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: isWide ? '100%' : '50%', margin: '0 auto' }}>
                      {(shouldGroup) ? (
                        <RowGroup
                          style={{
                            marginBottom: isSameAsNext ? '0' : '20px', marginTop: isSameAsPrev ? '0' : '20px',
                            borderTopRightRadius: isSameAsPrev ? '0' : '10px', borderTopLeftRadius: isSameAsPrev ? '0' : '10px',
                            borderBottomRightRadius: isSameAsNext ? '0' : '10px', borderBottomLeftRadius: isSameAsNext ? '0' : '10px',
                            borderBottom: isSameAsNext ? '1px solid rgba(0, 0, 0, 0.05)' : 0   
                          }}>
                      
                          {question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.SCALE ? (
                            <>     
                            {question?.choices?.length == 4 ? (
                              <>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <RequiredIndicatorContainer isRequired={question?.is_required}
                                    style={{ marginTop: question?.choices?.length === 4 && shouldGroup && !isSameAsPrev ? '5%' : 0 }}>
                                    {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                    <QuestionText>{question?.question_text}</QuestionText>
                                  </RequiredIndicatorContainer>

                                <Table>  
                                  <Thead>    
                                  {question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.SCALE &&
                                   question?.choices?.length === 4 &&
                                   shouldGroup &&
                                   !isSameAsPrev && (
                                    <Tr>
                                      <Th style={{ margin: 0 }}>Stämmer inte</Th>
                                      <Th>Stämmer ibland</Th>
                                      <Th>Stämmer oftast</Th>
                                      <Th>Stämmer helt</Th>
                                    </Tr>
                                          )}
                                  </Thead>
                                  <Tbody>
                                    {question?.choices.map((choice: any) => (
                                      <Tr>
                                        <TdText>
                                        <input
                                          type="radio"
                                          id={`questions.${question?.id}`}
                                          name={`questions.${question?.id}`}
                                          value={choice?.id}
                                          checked={values.questions?.[question?.id] === choice?.id}
                                          onChange={() => setFieldValue(`questions.${question?.id}`, choice?.id)}
                                          style={{
                                            width: isMobile ? '18px' : isWide ? '24px' : '30px',
                                            height: isMobile ? '18px' :  isWide ? '24px' : '30px',
                                            accentColor: 'rgba(170, 17, 103, 1)',
                                            cursor: 'pointer'
                                          }}
                                          />
                                        </TdText>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                    </Table>
                                    
                                </div>
                              </>) : question?.choices?.length == 5 ?  (
                                <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', alignItems: isMobile || isTablet ? 'flex-start' : 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <RequiredIndicatorContainer isRequired={question?.is_required} style={{ flex: 1, width: isMobile || isTablet ? '100%' : '45%' }}>
                                    {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                    <QuestionText>{question?.question_text}</QuestionText>
                                  </RequiredIndicatorContainer>
                                  <QuestionContent style={{ marginLeft: 'auto', flexDirection: 'row', marginTop: isMobile || isTablet ? 10 : 0 }}>
                                    {question?.choices.map((choice: any, choiceIndex: number) => (
                                      <div
                                        key={choiceIndex}
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: isMobile || isTablet ? '0 5px' : '0 10px', cursor: 'pointer' }}
                                        onMouseOver={() => handleStarMouseEnter(question?.id, choiceIndex + 1)}
                                        onMouseOut={() => handleStarMouseLeave(question?.id)}
                                        onClick={() => handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex + 1)}
                                      >
                                        {hoveredStars[question?.id] && hoveredStars[question?.id]! >= choiceIndex + 1 ? (
                                          <FaStar color="gold" size={isMobile ? 25 : 35} />
                                        ) : selectedStars[question?.id] && selectedStars[question?.id] >= choiceIndex + 1 ? (
                                          <FaStar color="gold" size={isMobile ? 25 : 35} />
                                        ) : (
                                          <FaStar color="lightGrey" size={isMobile ? 25 : 35}/>
                                        )}
                                      </div>
                                    ))}
                                  </QuestionContent>
                                </div>
                                ) : question?.choices?.length === 6 ? (
                                  <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', alignItems: isMobile || isTablet ? 'flex-start' : 'center', justifyContent: 'space-between', width: '100%' }}>
                                <RequiredIndicatorContainer isRequired={question?.is_required}>
                                {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                  <QuestionText>{question?.question_text}</QuestionText>
                                    </RequiredIndicatorContainer>
                                    
                                  <QuestionContent style={{ marginLeft: 'auto', flexDirection: 'row' , marginTop: isMobile || isTablet ? 20 : 0 }}>
                                  {question?.choices?.length === 6 && question?.choices.map((choice: any, choiceIndex: number) => (
                                    <SmilieContainer
                                    key={choiceIndex}
                                    onClick={() => handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex + 1)}
                                    >
                                    {renderIcon(choiceIndex, selectedStars[question?.id] === choiceIndex + 1)}
                                    </SmilieContainer>    
                                  ))}
                                  </QuestionContent>
                                      
                                </div>
                                  ) : (
                                    <>
                                    <RequiredIndicatorContainer isRequired={question?.is_required}>
                                    {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                      <QuestionText>{question?.question_text}</QuestionText>
                                        </RequiredIndicatorContainer>
                                        
                                    </>
                              )}
                                <QuestionContent style={{ display: 'flex', flexDirection: 'row'}}>  
                                {question?.choices?.length == 11 && 
                                 <ScaleOneToTenContainer>
                                  {question?.choices?.length == 11 && question?.choices.map((choice: any, choiceIndex: number) => (
                                      <StyledChoiceButton
                                      key={choiceIndex}
                                      selected={selectedStars[question?.id] === choiceIndex}
                                      onClick={() =>
                                        handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex)
                                      }
                                    >
                                      {choiceIndex}
                                    </StyledChoiceButton>
                                  ))}
                                  </ScaleOneToTenContainer>
                                  }
                            
                                   </QuestionContent>   
                                {question?.comment_required && (
                                <CenteredContainer type={question?.choices?.length == 4}>
                                  <CommentWidth>
                                    <RiInformationLine color="gray" size={20} />
                                      <FormikTextarea
                                        name={`comments.${question?.id}`}
                                        label={''}
                                        placeholder='If you something to add, please write here (optional)'
                                      />
                                  </CommentWidth>
                                  </CenteredContainer>
                                )}                                          
                                  </>
                            
                          ) : question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.BOOLEAN ? (
                              <>
                              <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '20%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <QuestionContent>
                                          <div style={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                                            {question?.choices.map((choice: any, choiceIndex: number) => (
                                              <div key={choiceIndex} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginRight: '20px' }}>
                                                <input
                                                  type="radio"
                                                  id={`questions.${question?.id}`}
                                                  name={`questions.${question?.id}`}
                                                  value={choice?.id}
                                                  checked={values.questions?.[question?.id] === choice?.id}
                                                  onChange={() => setFieldValue(`questions.${question?.id}`, choice?.id)}
                                                  style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    color: 'red',
                                                    marginRight: '8px',
                                                    marginTop: 4,
                                                    accentColor: 'rgba(170, 17, 103, 1)',
                                                    cursor: 'pointer'
                                                  }}
                                                />
                                                <label htmlFor={`questions.${question?.id}`} style={{ fontSize: '14px' }}>
                                                  {choice?.choice_text}
                                                </label>
                                              </div>          
                                            ))}
                                          </div>
                                        </QuestionContent>
                                        {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                                placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}                                      
                              </>
                          ) : question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.STRING ? (
                                <>
                                  <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '30%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <div style={{ width: '65%'}}>
                                          <FormikInput
                                            name={`questions.${question?.id}`}
                                            label={''}
                                            placeholder='Type here'
                                            />
                                            </div>                        
                                        {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                                placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}                 
                                </>
                          ) : question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.DROPDOWN && (
                                  <>
                                   <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '20%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <QuestionContent style={{ width: '100%', alignItems: isMobile || isTablet ? 'flex-start' : 'center' }}>
                                          {question?.choice_type?.is_multiple_choice ? (
                                            <CustomSelectMulti
                                              value={values.questions?.[question?.id] ?? null}
                                              onChange={(selectedOption: any) =>
                                                setFieldValue(`questions.${question?.id}`, selectedOption)
                                              }
                                              placeholder={'choose here'}
                                              options={question?.choices.map((choice: any) => ({ label: choice?.choice_text, value: choice?.id }))}
                                            />
                                          ) : (
                                            <CustomSelect
                                              value={values.questions?.[question?.id] ?? null}
                                              onChange={(selectedOption: any) =>
                                                setFieldValue(`questions.${question?.id}`, selectedOption)
                                              }
                                              placeholder={'choose here'}
                                              options={question?.choices.map((choice: any) => ({ label: choice?.choice_text, value: choice?.id }))}
                                            />
                                          )}
                                        </QuestionContent>                                           
                                      {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                               placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}                                      
                              </>                
                          )}  
                        </RowGroup>
                      ) : (
                          <RowGroup style={{ marginTop: 20, marginBottom: 20, borderRadius: 10 }}>
                            {question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.DROPDOWN ? (
                              <>
                                 <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '20%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <QuestionContent style={{ width: '100%', alignItems: isMobile || isTablet ? 'flex-start' : 'center'}}>
                                          {question?.choice_type?.is_multiple_choice ? (
                                            <CustomSelectMulti
                                              value={values.questions?.[question?.id] ?? null}
                                              onChange={(selectedOption: any) =>
                                                setFieldValue(`questions.${question?.id}`, selectedOption)
                                              }
                                              placeholder={'choose here'}
                                              options={question?.choices.map((choice: any) => ({ label: choice?.choice_text, value: choice?.id }))}
                                            />
                                          ) : (
                                            <CustomSelect
                                              value={values.questions?.[question?.id] ?? null}
                                              onChange={(selectedOption: any) =>
                                                setFieldValue(`questions.${question?.id}`, selectedOption)
                                              }
                                              placeholder={'choose here'}
                                              options={question?.choices.map((choice: any) => ({ label: choice?.choice_text, value: choice?.id }))}
                                            />
                                          )}
                                        </QuestionContent>                                           
                                      {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                               placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}                                      
                              </>
                            ) : question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.STRING ? (
                                <>
                                 <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '30%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <div style={{ width: '65%'}}>
                                          <FormikInput
                                            name={`questions.${question?.id}`}
                                            label={''}
                                            placeholder='Type here'
                                            />
                                            </div>                        
                                        {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                                placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}                 
                                </>
                              
                              ): question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.BOOLEAN ? (
                                  <>
                                   <RequiredIndicatorContainer isRequired={question?.is_required}>
                                        {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                        <QuestionText>{question?.question_text}</QuestionText>
                                      </RequiredIndicatorContainer>
                                      {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '20%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                                        <QuestionContent>
                                          <div style={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                                            {question?.choices.map((choice: any, choiceIndex: number) => (
                                              <div key={choiceIndex} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginRight: '20px' }}>
                                                <input
                                                  type="radio"
                                                  id={`questions.${question?.id}`}
                                                  name={`questions.${question?.id}`}
                                                  value={choice?.id}
                                                  checked={values.questions?.[question?.id] === choice?.id}
                                                  onChange={() => setFieldValue(`questions.${question?.id}`, choice?.id)}
                                                  style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    color: 'red',
                                                    marginRight: '8px',
                                                    marginTop: 4,
                                                    accentColor: 'rgba(170, 17, 103, 1)',
                                                    cursor: 'pointer'
                                                  }}
                                                />
                                                <label htmlFor={`questions.${question?.id}`} style={{ fontSize: '14px' }}>
                                                  {choice?.choice_text}
                                                </label>
                                              </div>          
                                            ))}
                                          </div>
                                        </QuestionContent>
                                        {question?.comment_required && (
                                        <CenteredContainer>
                                          <CommentWidth>
                                            <RiInformationLine color="gray" size={20} />
                                              <FormikTextarea
                                                name={`comments.${question?.id}`}
                                                label={''}
                                                placeholder='If you something to add, please write here (optional)'
                                              />
                                          </CommentWidth>
                                          </CenteredContainer>
                                        )}        
                                  </>
                              ): question?.choice_type?.field_type === SurveyQuestionChoiceTypeEnum.SCALE && (
                                    <>   
                                  <RequiredIndicatorContainer isRequired={question?.is_required}>
                                    {question?.is_required && <RequiredIndicatorText>*</RequiredIndicatorText>}
                                    <QuestionText>{question?.question_text}</QuestionText>
                                  </RequiredIndicatorContainer>
                              {question?.image?.url && (<QuestionImage src={question?.image?.url} alt={question?.image?.alt} style={{ width: '30%', height: 'auto', margin: '10px 0', borderRadius: 10 }} />)}
                              <QuestionContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {question?.choices?.length == 4 &&
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                                    {question?.choices?.length == 4 && question?.choices.map((choice: any, choiceIndex: number) => (
                                      <div key={choiceIndex} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginRight: '20px' }}>
                                        <input
                                          type="radio"
                                          id={`questions.${question?.id}`}
                                          name={`questions.${question?.id}`}
                                          value={choice?.id}
                                          checked={values.questions?.[question?.id] === choice?.id}
                                          onChange={() => setFieldValue(`questions.${question?.id}`, choice?.id)}
                                          style={{
                                            width: '16px',
                                            height: '16px',
                                            color: 'red',
                                            marginRight: '8px',
                                            marginTop: 4,
                                            accentColor: 'rgba(170, 17, 103, 1)',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <label htmlFor={`questions.${question?.id}`} style={{ fontSize: '14px' }}>
                                          {choice?.choice_text}
                                        </label>
                                      </div>          
                                    ))}
                                  </div>
                                }
                                  {question?.choices?.length == 5 && question?.choices.map((choice: any, choiceIndex: number) => (
                                    <div
                                    key={choiceIndex}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 10px', cursor: 'pointer' }}
                                    onMouseOver={() => handleStarMouseEnter(question?.id, choiceIndex + 1)}
                                    onMouseOut={() => handleStarMouseLeave(question?.id)}
                                    onClick={() => handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex + 1)}
                                    >
                                    {hoveredStars[question?.id] && hoveredStars[question?.id]! >= choiceIndex + 1 ? (
                                        <FaStar color="gold" size={32} />
                                    ) : selectedStars[question?.id] && selectedStars[question?.id] >= choiceIndex + 1 ? (
                                        <FaStar color="gold" size={32} />
                                    ) : (
                                      <FaStar color="lightGrey" size={32} />
                                    )}
                                    </div>
                                  ))}
                                   
                                   {/* Vid length 6 */}
                                  {question?.choices?.length === 6 && question?.choices.map((choice: any, choiceIndex: number) => (
                                    <SmilieContainer
                                    key={choiceIndex}
                                    onClick={() => handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex + 1)}
                                    >
                                    {renderIcon(choiceIndex, selectedStars[question?.id] === choiceIndex + 1)}
                                    </SmilieContainer>
                                  ))}
                                
                                {question?.choices?.length == 11 && 
                                 <ScaleOneToTenContainer>
                                  {question?.choices?.length == 11 && question?.choices.map((choice: any, choiceIndex: number) => (
                                      <StyledChoiceButton
                                      key={choiceIndex}
                                      selected={selectedStars[question?.id] === choiceIndex}
                                      onClick={() =>
                                        handleStarClick(setFieldValue, question?.id, choice?.id, choiceIndex)
                                      }
                                    >
                                      {choiceIndex}
                                    </StyledChoiceButton>
                                  ))}
                                  </ScaleOneToTenContainer>
                                  }
      
                                </QuestionContent>   
                                {question?.comment_required && (
                                <CenteredContainer>
                                  <CommentWidth>
                                    <RiInformationLine color="gray" size={20} />
                                      <FormikTextarea
                                        name={`comments.${question?.id}`}
                                        label={''}
                                        placeholder='If you something to add, please write here (optional)'
                                      />
                                  </CommentWidth>
                                  </CenteredContainer>
                                )}        
                                </>
                              )}
                          </RowGroup>
                      )}
                    </div>
                    )})}
                
            </DataContainer>
              {currentPage > 0 ? (
                <Button
                  appearance='secondary'
                  onClick={handlePreviousPage}
                  type='button'
                  style={{ position: 'absolute', left: 10, bottom: 5 }}
                >
                  <MdOutlineNavigateBefore />
                  Previous
                </Button>
              ) : <div />}
              {currentPage < data?.questions?.length - 1 ? (
                <Button
                  appearance='secondary'
                  onClick={handleNextPage}
                  type='button'
                  style={{ position: 'absolute', right: 10, bottom: 5 }}
                >
                  Next
                  <MdOutlineNavigateNext />
                </Button>
              ) : (
                <Button
                  appearance='secondary'
                  type='button'
                  onClick={() => { handleSubmit(values) }}
                  style={{ position: 'absolute', right: 10, bottom: 5 }}
                >
                  Submit
                </Button>
              )}
            </Form>          
          )}
      </Formik>
      {completed && 
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgb(249, 246, 248)', zIndex: 100 }}>
          <GoCheckCircle color="green" size={150} />
          <span style={{ fontSize: 24, fontWeight: 'bold', color: 'green', textAlign: 'center', marginTop: 20 }}>Thank you!</span>
          <p style={{ marginTop: 5, fontWeight: 400, color: 'green' }}>Survey is completed</p>
        </div>
      }
      {toastError && <ToastError message={toastErrorMessage} />}
      </FormPaper>
  )
}

export default List