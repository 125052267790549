import React, { useEffect, useState } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import {
  Container, FlexContainer, ButtonTabs,
 } from './Dashboard.styles';
import { CircleChart } from '../../ui-kit/Chart/CircleChart';
import { PerformanceChart } from '../../ui-kit/Chart/PerformanceChart';
import CustomCalendar from '../../ui-kit/Calendar/CustomCalendar';
import CourseCard from '../../ui-kit/Card/CourseCard';
import {
  useGetCompanyDashboardQuery,
  useGetPersonalDashboardQuery
} from '../../slices/dashboardApiSlice';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Hero from '../../ui-kit/Hero/Hero';
import { DashboardPermissions } from '../../enum/Dashboard/DashboardPermissions';
import { TaskList } from '../Dashboard/TaskList/TaskList';
import RiskList from './Risk/RiskList';
import { useDownloadTaskWithRisksMutation } from '../../slices/taskApiSlice';
import Button from '../../ui-kit/Button/Button';

export const Dashboard = () => {
  const { intl } = useIntlContext();
  const location = useLocation();
  const { userInfo, permissions, userCompany } = useSelector((state: any) => state.auth);
  const [filter, setFilter] = useState<string>('1');
  
  const user = userCompany?.button_color;

  const canDo = (permission: string) => {
    return permissions && !!permissions[permission];
  };
  const canSeeDashboardCompany = canDo(DashboardPermissions.isAllowedSeeDashboardCompany);

  const [courseData, setCourseData] = useState([
    { title: '', percent: 0 },
    { title: '', percent: 0 },
    { title: '', percent: 0 },
  ]);

  const [performanceData, setPerformanceData] = useState([
    { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: 0 },
    { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: 0 },
  ]);

  const [courses, setCourses] = useState([]);

  const [downloadTaskWithRisk] = useDownloadTaskWithRisksMutation();
  
  const {
    data: companyData,
    refetch: refetchCompany,
  } = useGetCompanyDashboardQuery({}, { skip: !canSeeDashboardCompany });

  const {
    data: personalData,
    refetch: refetchPersonal,
  } = useGetPersonalDashboardQuery({}, { skip: canSeeDashboardCompany });

  const dashboardData = canSeeDashboardCompany ? companyData : personalData;
  const refetch = canSeeDashboardCompany ? refetchCompany : refetchPersonal;
  
  const { data: coursesData, refetch: refetchCourses,} = useGetPersonalDashboardQuery({});
  
  useEffect(() => {
    if ((canSeeDashboardCompany && companyData) || (!canSeeDashboardCompany && personalData)) {
      refetch();
    }
    refetchCourses();
  }, [location.key, refetch, canSeeDashboardCompany, companyData, personalData]);

  useEffect(() => {
    if (dashboardData) {
      setCourseData([
        { title: formatMessage({ id: 'dashboard.completed', defaultMessage: 'Completed' }), percent: dashboardData.data.statistic?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.inProgress', defaultMessage: 'In progress' }), percent: dashboardData.data.statistic?.in_progress || 0 },
        { title: formatMessage({ id: 'dashboard.toBegin', defaultMessage: 'To begin' }), percent: dashboardData.data.statistic?.to_begin || 0 },
      ]);

      setPerformanceData([
        { title: formatMessage({ id: 'dashboard.completedSuccessfully', defaultMessage: 'Completed Successfully' }), percent: dashboardData.data.performance?.completed || 0 },
        { title: formatMessage({ id: 'dashboard.completedUnsuccessfully', defaultMessage: 'Completed Unsuccessfully' }), percent: dashboardData.data.performance?.failed || 0 },
      ]);
    }
  }, [dashboardData, intl]);

  useEffect(() => {
    if (coursesData) {
      setCourses(coursesData.data.courses.map((course: any) => (course)))
    }
  }, [coursesData, intl])

  const welcome = formatMessage({ id: 'hero.header_22', defaultMessage: ', welcome to the ' });
  
  const downloadTaskWithRisks = async () => {
    try {
      const response = await downloadTaskWithRisk({}).unwrap();
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'tasks_with_risks.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Container>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Hero
          description={formatMessage({
            id: 'hero.description',
            defaultMessage:
              'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.',
          })}
          imagePath={'assets/hero2.jpg'}
          header={formatMessage({ id: 'hero.header_2', defaultMessage: userInfo?.data?.firstname + ' ' + userInfo?.data?.lastname + welcome })}
          subHeader={formatMessage({ id: 'hero.subHeaderDashboard', defaultMessage: 'Here you will find your dashboard' })}
        ></Hero>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', cursor: 'normal', marginBottom: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '296px', height: '36px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
          <ButtonTabs active={filter === '1'} color={user} style={{ width: 164 }} onClick={() => setFilter('1')}>DASHBOARD</ButtonTabs>
          <ButtonTabs active={filter === '2'} color={user} style={{ width: 154, textTransform: 'uppercase' }} onClick={() => setFilter('2')}>{formatMessage({ id: 'dashboard.myCourses', defaultMessage: 'My courses' })}</ButtonTabs>
        </div>
      </div>
      {filter === '1' && (
      <>
      <FlexContainer>
        <CircleChart data={courseData} title={formatMessage({ id: 'dashboard.courseStatistics', defaultMessage: 'Course Statistics' })} />
        <PerformanceChart data={performanceData} title={formatMessage({ id: 'dashboard.performanceStatistics', defaultMessage: 'Performance' })} />
        <CustomCalendar />
      </FlexContainer>
          
        <div style={{ flex: 'wrap', marginTop: 50, border: '1px solid rgba(234, 231, 242, 1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: 50, }}>     
          <TaskList user={user} />
          </div>
            <div style={{ flex: 'wrap', marginTop: 50, border: '1px solid rgba(234, 231, 242, 1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: 50, }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
              <h1>
                Risk
              </h1>

              <Button onClick={()=>{ downloadTaskWithRisks() }}>
               {formatMessage({ id: 'dashboard.download', defaultMessage: 'Export to Excel' })}
              </Button>
            </div>      
            <RiskList />
          </div>
      </>
      )}

    {filter === '2' && (
      <>
           {courses.length > 0 ? (
            <>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
              {courses.map((course: any, index: number) => (
                <CourseCard key={index} course={course} />
              ))}
            </div>
            </>  
          ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
            <h1>{formatMessage({ id: 'task.noCourses', defaultMessage: 'No courses available' })}</h1>
          </div>
          )}
      </>
    )}
         
    </Container>
  );
};

export default Dashboard;
