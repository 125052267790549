import React from 'react';
import Select from 'react-select';
import { StylesConfig } from 'react-select';
import { useSelector } from 'react-redux';

interface CustomSelectProps {
  label: any;
  value: any;
  options: { value: any; label: string }[];
  onChange: (selectedOption: any) => void;
  placeholder: string;
  disabled?: boolean;
  height?: string;
  marginTop?: any
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, value, options, onChange, placeholder, disabled, height, marginTop = 10  }) => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 14,
      borderRadius: 8,
      minHeight: height || provided.height,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 12,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 105,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };

  return (
    <div>
      <label
        htmlFor="custom-select"
        style={{
          textAlign: 'left',
          marginTop: marginTop,
          marginBottom: '0.5rem',
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'black',
          display: 'inline-block',
        }}
      >
        {label}
      </label>
      <Select
        inputId="custom-select"
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable
        styles={customStyles}
        aria-label={label}
        isDisabled={disabled}
        
      />
    </div>
  );
};

export default CustomSelect;