import styled from 'styled-components';

export const Container = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const Input = styled.input<{ color?: string }>`
  width: 100%;
  padding: 0.55rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 14px;
  resize: none;
  placeholder: rgba(141, 141, 141, 1);
  font-weight: 500;
   &:hover {
    border-color: ${({ color }) => color};
  }
    &:focus {
    border-color: ${({ color }) => color};
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const P = styled.p`
  margin-bottom: 10px;    
  font-size: 18px;
  font-weight: 500;
`;