import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../../../../ui-kit';
import styles from './DatePicker.module.css';
import {
  FormPaper,
  Content,
  Title,
  Actions,
  CheckboxWrapper,
  LabelWrapper,
} from './Schedule.styles';
import { useUpdateSurveyScheduleMutation } from '../../../../slices/surveyApiSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { formatMessage } from '../../../../locale';
import { CourseStatusEnum } from '../../../../enum/Course/CourseStatusEnum';

interface SurveyProps {
  survey?: any;
  onChanged?: () => void;
}

const Schedule: React.FC<SurveyProps> = ({ survey, onChanged }) => {
  const [updateSurveySchedule] = useUpdateSurveyScheduleMutation();
  const [noEndDate, setNoEndDate] = useState<boolean>(survey?.expired_at === '' || survey?.expired_at === null);

  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const initialValues = {
    started_at: survey?.started_at ? new Date(survey?.started_at) : null,
    expired_at: survey?.expired_at ? new Date(survey?.expired_at) : null,
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await updateSurveySchedule({
        id: survey?.id,
        data: {
          started_at: values.started_at ? (() => { values.started_at.setMinutes(values.started_at.getMinutes() - values.started_at.getTimezoneOffset()); return values.started_at.toISOString().split('T')[0]; })() : null,
          expired_at: noEndDate ? '' : values.expired_at ? (() => { values.expired_at.setMinutes(values.expired_at.getMinutes() - values.expired_at.getTimezoneOffset()); return values.expired_at.toISOString().split('T')[0]; })() : null,
        },
      }).unwrap();
      onChanged?.();
    } catch (error) {
      console.error('Failed to update schedule:', error);
    }
  };

  const handleNoEndDateChange = () => {
    setNoEndDate(!noEndDate);
  };

  const renderEndDatePicker = (values: any, setFieldValue: any) => (
    <LabelWrapper style={{ marginTop: 10 }}>
      <label htmlFor="expired_at">
        {formatMessage({ id: 'schedule.expiredAt', defaultMessage: 'End Date' })}
      </label>
      <DatePicker
        selected={values.expired_at}
        onChange={(date) => setFieldValue('expired_at', date)}
        name="expired_at"
        dateFormat="yyyy-MM-dd"
        minDate={values.started_at || new Date()}
        className={styles.datePicker}
        calendarClassName={styles.calendar}
        autoComplete="off"
      />
    </LabelWrapper>
  );

  const shouldShowEndDatePicker = () => (survey?.status?.enum === CourseStatusEnum.STARTED ||
    survey?.status?.enum === CourseStatusEnum.PUBLISHED) && survey?.expired_at;

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Title>
              {formatMessage({ id: 'schedule.title', defaultMessage: 'Edit Schedule' })}
            </Title>
            <Content>
              <LabelWrapper>
                <label htmlFor="started_at">
                  {formatMessage({ id: 'schedule.startedAt', defaultMessage: 'Start Date *' })}
                </label>
                <DatePicker
                  selected={values.started_at}
                  onChange={(date) => setFieldValue('started_at', date)}
                  name="started_at"
                  dateFormat="yyyy-MM-dd"
                  className={styles.datePicker}
                  calendarClassName={styles.calendar}
                  autoComplete="off"
                />
              </LabelWrapper>
              {shouldShowEndDatePicker() ? (
                renderEndDatePicker(values, setFieldValue)
              ) : (
                  <div>
                    {!noEndDate && renderEndDatePicker(values, setFieldValue)}
                  <CheckboxWrapper style={{ marginTop: 20 }}>
                    <input
                      type="checkbox"
                      id="noEndDate"
                      checked={noEndDate}
                      onChange={handleNoEndDateChange}
                    />
                    <label htmlFor="noEndDate">
                      {formatMessage({ id: 'schedule.noEndDate', defaultMessage: 'No End Date' })}
                    </label>
                  </CheckboxWrapper>
                  
                </div>
              )}
            </Content>
            {canDo('survey: create') && (
              <Actions>
                <Button appearance="primary" type="submit">
                  {formatMessage({ id: 'schedule.saveChanges', defaultMessage: 'Save Changes' })}
                </Button>
              </Actions>
            )}
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};

export default Schedule;
