import React, { useState } from 'react'
import { ProfileDetails } from './components/ProfileDetails'
import { ProfileUploads } from './components/ProfileUploads'
import { TabsContainer, TabButton, TabContent } from './ProfileTabs.styles'
import Paper from '../../ui-kit/Paper/Paper'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'

export const Profile: React.FC = () => {
  const [activeTab, setActiveTab] = useState<any>('profile')
  const { intl, setLocale } = useIntlContext()
  const { userCompany } = useSelector((state: any) => state.auth);
    

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileDetails />
      case 'files':
        return <ProfileUploads type="certificate" />
      case 'documents':
        return <ProfileUploads type="documents" />
      default:
        return null
    }
  }
  return (
    <Paper>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '60%', cursor: 'normal', marginBottom: 40, marginTop: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '40px', justifyContent: 'space-between', border: '0.063rem solid #D2D4D7', borderRadius: '8px', boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)' }}>
            <TabButton
            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
            color={userCompany?.button_color}
            active={activeTab === 'profile'}
            onClick={() => setActiveTab('profile')}
            >
            {formatMessage({
              id: 'profile.buttonProfile',
              defaultMessage: 'Profile',
            })}
            </TabButton>
          
          <TabButton
            style={{
              borderRadius: activeTab !== 'documents' ? '0px' : undefined,
              borderLeft: activeTab !== 'documents' ? '0.063rem solid #D2D4D7' : undefined,
              borderRight: activeTab !== 'documents' ? '0.063rem solid #D2D4D7' : undefined
            }}
          color={userCompany?.button_color}
          active={activeTab === 'documents'}
          onClick={() => setActiveTab('documents')}
        >
          {formatMessage({
            id: 'profile.buttonDocuments',
            defaultMessage: 'Documents',
          })}
        </TabButton>
          <TabButton
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
          color={userCompany?.button_color}
          active={activeTab === 'files'}
          onClick={() => setActiveTab('files')}
        >
          {formatMessage({
            id: 'profile.buttonCertificates',
            defaultMessage: 'Certificates',
          })}
        </TabButton>
        </div>
        </div>
      <TabContent>{renderTabContent()}</TabContent>
      {process.env.REACT_APP_VERSION &&
        <div style={{ position: 'absolute', bottom: '0.5rem', right: '0.8rem' }}>
          <p style={{ fontWeight: '500', fontSize: 10 }}>V.{process.env.REACT_APP_VERSION}</p>
        </div>}
    </Paper>
  )
}
