import { Link, useNavigate } from 'react-router-dom'
import  Paper  from '../../ui-kit/Paper/Paper'
import { paths } from '../../config/paths'
import { LuArrowLeft } from 'react-icons/lu'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useViewHandbookQuery } from '../../slices/handbooksApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
import ButtonForTask from '../../ui-kit/Modal/SaveTaskInfoModal/ButtonForTask'

export const HandbookPage = () => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: handbook,
    error,
    isLoading,
    refetch,
  } = useViewHandbookQuery({ id })

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>
  if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingHandbook', defaultMessage: 'Error while loading handbook' })}</p></div>;

  const handbookData = handbook?.data
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'
  
  return (
    <Paper>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <h2>{handbookData?.title}</h2>
        </div>
        <div>
          <Link to={paths.handbook} className={'link'}>
            <p style={{color: color}}><LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'handbook.goBackHandbook', defaultMessage: 'BACK TO HANDBOOK' })}</p>
          </Link>
        </div>
      </div>
      <div>
        <HtmlView content={handbookData?.content || ''}></HtmlView>
      </div> 
      {handbookData?.task_type_id &&
        <ButtonForTask data={handbookData}/>
      } 
    </Paper>
  )
}
