import React, { useEffect, useState, useRef } from 'react'
import update from 'immutability-helper'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PiDotsSixVerticalBold } from 'react-icons/pi'
import { LuX } from 'react-icons/lu'
import QuizForm from './QuestionsForm'
import {
  Buttons,
  QuizContainer,
  QuizFormContainer,
  QuizList,
  QuizListItem,
  QuizSidebar,
  NoQuiz,
  QuizDetailsButton,
  Title,
  PageTitle,
  Actions,
} from './Questions.styles'
import { Button } from '../../../../ui-kit'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { useDeleteSurveyQuestionMutation, useSortServeyQuestionsMutation } from '../../../../slices/surveyApiSlice'
import ConfirmationModal from '../../../../ui-kit/Modal/ConfirmationModal'

interface QuizProps {
  mode: 'create' | 'edit';
  survey?: any;
  onChanged?: () => void;
}

export type QuizMode = 'create' | 'edit';

const Quiz = ({ mode, survey, onChanged }: QuizProps) => {
  const { intl } = useIntlContext()
  const [modes, setModes] = useState<QuizMode>('create')
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null)

  const [deleteSurveyQuestion] = useDeleteSurveyQuestionMutation()
  const [sortSurveyQuestions] = useSortServeyQuestionsMutation() 

  const [questionsOrder, setQuestionsOrder] = useState<any[]>([])
  const [group, setGroup] = useState<any>(null)
  const { userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  useEffect(() => {
    setModes('create');
    const sortedQuestions = survey?.questions?.reduce((acc: any[], group: any) => {
      const sortedGroupQuestions = group.questions.slice().sort((a: any, b: any) => a.sort - b.sort);
      return [...acc, ...sortedGroupQuestions];
    }, []) || [];
    setQuestionsOrder(sortedQuestions);
  }, [survey]);

  const handleCreateQuestion = () => {
    setSelectedQuestion(null)
    setModes('create')
  }

  const handleEditQuestion = (questionId: number) => {
    const questionIndex = questionsOrder.findIndex((question: any) => question.id === questionId);
    const questionGroup = survey?.questions?.find((group: any) => group.questions.some((q: any) => q.id === questionId))?.group;
    console.log('questionIndex', questionsOrder);
    setSelectedQuestion(questionIndex);
    setGroup(questionGroup);
    setModes('edit');
  }

  const handleDeleteQuestion = async (questionId: number) => {
       await deleteSurveyQuestion({ id: survey.id, questionId }).unwrap() 
      onChanged?.()
  }

  const moveQuestion = (dragIndex: number, hoverIndex: number) => {
    const dragQuestion = questionsOrder[dragIndex]
    const hoverQuestion = questionsOrder[hoverIndex]
  
    // Check if both questions belong to the same group
    const dragGroup = survey?.questions?.find((group: any) => group.questions.some((q: any) => q.id === dragQuestion.id))?.group
    const hoverGroup = survey?.questions?.find((group: any) => group.questions.some((q: any) => q.id === hoverQuestion.id))?.group
  
    if (dragGroup !== hoverGroup) {
      return
    }
  
    const updatedQuestions = update(questionsOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragQuestion],
      ],
    })
    setQuestionsOrder(updatedQuestions)
  }
  const handleDragEnd = async () => {
     await sortSurveyQuestions({
      id: survey.id,
      questionsIds: questionsOrder.map((question: any) => question.id),
    }).unwrap()
    onChanged?.()  
  }

  const renderQuizContent = () => (
    <QuizSidebar>
      <QuizDetailsButton color={userCompany?.button_color}>
       {formatMessage({ id: 'quiz.questionsToQuiz', defaultMessage: 'Questions to quiz' })}
      </QuizDetailsButton>
      {questionsOrder.length === 0 && mode === 'create' ? (
        <NoQuiz>
          <div>{formatMessage({ id: 'quiz.noQuestions', defaultMessage: 'There are no questions in this quiz yet'})}</div>
          {canDo('survey: update') && (
            <Button appearance="primary" onClick={handleCreateQuestion}>
             {formatMessage({ id: 'quiz.newQuestion', defaultMessage: 'New question' })}
            </Button>
          )}
        </NoQuiz>
      ) : (
        <>
          <QuizList>
            {[...(survey?.questions || [])]
              .sort((a: any, b: any) => a.group - b.group)
              .map((group: any) => (
              <div key={group.group}>
                <PageTitle >{`${formatMessage({ id: 'quiz.page', defaultMessage: 'Page' })} ${group.group}`}</PageTitle>
                {group.questions.map((question: any) => (
                <DraggableQuestion
                  key={question.id}
                  index={questionsOrder.findIndex((q: any) => q.id === question.id)}
                  question={question}
                  questionsOrder={questionsOrder}
                  survey={survey}
                  moveQuestion={moveQuestion}
                  onDragEnd={handleDragEnd}
                  onClick={() => handleEditQuestion(question.id)}
                  handleDeleteQuestion={handleDeleteQuestion}
                />
                ))}
              </div>
              ))}
          </QuizList>
          {canDo('survey: update') && (
            <Buttons>
              <Button appearance="primary" onClick={handleCreateQuestion}>
                {formatMessage({ id: 'quiz.addQuestion', defaultMessage: 'Add question' })}
              </Button>
            </Buttons>
          )}
        </>
      )}
    </QuizSidebar>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <QuizContainer>
        {renderQuizContent()}
          <QuizFormContainer>
            <QuizForm
              mode={modes}
              surveyId={survey?.id}
              group={group}
              questionId={selectedQuestion !== null ? questionsOrder[selectedQuestion].id : undefined}
              onChanged={() => {
                onChanged?.()
                setModes('create')
              }}
              onResetMode={() => setModes('create')} 
            />
          </QuizFormContainer>
      </QuizContainer>
    </DndProvider>
  )
}

export default Quiz

interface DraggableQuestionProps {
  question: any;
  index: number;
  questionsOrder: any[];
  survey: any;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: () => void;
  onClick: () => void;
  handleDeleteQuestion: any;
}

const DraggableQuestion: React.FC<DraggableQuestionProps> = ({
                                                               question,
                                                               index,
                                                               questionsOrder,
                                                               survey,
                                                               moveQuestion,
                                                               onDragEnd,
                                                               onClick,
                                                               handleDeleteQuestion,
                                                             }) => {
  const ref = useRef<HTMLLIElement>(null)
  const [, drop] = useDrop({
    accept: 'question',
    hover(item: { index: number }) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      // Check if both questions belong to the same group
      const dragQuestion = questionsOrder[dragIndex]
      const hoverQuestion = questionsOrder[hoverIndex]
      const dragGroup = survey?.questions?.find((group: any) => group.questions.some((q: any) => q.id === dragQuestion.id))?.group
      const hoverGroup = survey?.questions?.find((group: any) => group.questions.some((q: any) => q.id === hoverQuestion.id))?.group

      if (dragGroup !== hoverGroup) {
        return
      }

      moveQuestion(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'question',
    item: { type: 'question', id: question.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: onDragEnd,
  })

  drag(drop(ref))


  const { permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const [openModal, setOpenModal] = useState(false)

  return (
    <QuizListItem
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <PiDotsSixVerticalBold size={22} />
      <span onClick={onClick}>{question.question_text}</span>
      {canDo('survey: update')  && (
        <LuX
          size={20}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
          onClick={() => setOpenModal(true)}
        />
      )}
      {openModal && (
        <ConfirmationModal
          header={formatMessage({ id: 'quiz.modalMessage', defaultMessage: 'Are you sure you want to delete this question?' })}
          onConfirm={() => {
            handleDeleteQuestion(question.id)
            setOpenModal(false)
          }}
          onCancel={() => setOpenModal(false)}
        />
      )}
    </QuizListItem>
  )
}
