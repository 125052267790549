import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../locale';
import Button from '../../../ui-kit/Button/Button';
import { Actions, FormHeader, FormPaper, TabContent, FormContent, Column, Header, ButtonIcon, ListContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  ColumnTopLevel,
} from './TaskTypeForm.style';
import { paths } from '../../../config/paths';
import Row from '../../../ui-kit/grid/Row';
import { FormikInput, FormikTextarea, FormikCheckbox } from '../../../ui-kit';
import { Form, Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import { CustomFieldPermisson } from '../../../enum/TaskPermisson/CustomFieldPermisson';
import { LuPlusCircle } from "react-icons/lu";
import { HideOnMobile } from '../../../components/BaseLayout/BaseLayout.styles'
import ButtonSecondary from '../../../ui-kit/Button/ui/components/ButtonSecondary' 
import ConfirmationModal from '../../../ui-kit/Modal/ConfirmationModal';
import ModalConent from './ModalContent/ModalContent';
import ModalContentView from '../Components/ModalContent/ModalContentView/ModalContentView';
import { setCustomFieldCreated } from '../../../slices/customFieldSliceCreatedSlice';
import {
  useCreateTaskTypeMutation, useUpdateTaskTypeMutation,
  useGetOneTaskTypeQuery
} from '../../../slices/taskTypeApiSlice';
import NoticeModal from '../../../ui-kit/Modal/NoticeModal';
import WangEditor from '../../../ui-kit/Form/components/Editor/WangEditorTaskType';
import WangEditorMain from '../../../ui-kit/Form/components/Editor/WysiwygEditor';
import { v4 as uuidv4 } from 'uuid';
import { useGetAllDepartmentsSimpleQuery } from '../../../slices/departmentsApiSlice';
import { useGetAllOfficesSimpleQuery } from '../../../slices/officeApiSlice';
import { useGetAllUsersQuery } from '../../../slices/usersApiSlices';
import { useGetAllJobTitlesSimpleQuery } from '../../../slices/jobTitleApiSlice';
import { useGetAllProjectGroupsSimpleQuery } from '../../../slices/projectGroupApiSlice';
import { useGetRolesCompanyNoIdQuery } from '../../../slices/companiesApiSlice';
import { transformToTree, flattenOptions } from '../../../slices/apiSlice';
import { MultiSelect } from '../../../ui-kit/MultiSelect/MultiSelect';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragableCustomField from './DragableCustomField/DragableCustomField';
import DropdownOption from './DropdownOption/DropdownOption';
import { RiExpandUpDownFill } from "react-icons/ri";

interface TaskTypeFormProps {
  mode: 'create' | 'edit';
}

interface TaskType {
  title?: string;
  value?: string;
}

export const TaskTypeForm = ({ mode }: TaskTypeFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { taskTypeId } = useParams<{ taskTypeId: string }>();
  const { customFieldCreated } = useSelector((state: any) => state.customFieldSliceCreated);
  
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState<TaskType | null>(null);
  const [departmentTree, setDepartmentTree] = useState<any[]>([]);
  const [taskTypes, setTaskTypes] = useState<any[]>([]);

  useEffect(() => {
    const taskType = [
      { id: CustomFieldPermisson.DROPDOWN, title: formatMessage({ id: 'taskType.dropdown', defaultMessage: 'Dropdown' }) },
      { id: CustomFieldPermisson.USER, title: formatMessage({ id: 'taskType.user', defaultMessage: 'User' }) },
      { id: CustomFieldPermisson.DEPARTMENT, title: formatMessage({ id: 'taskType.department', defaultMessage: 'Department' }) },
      { id: CustomFieldPermisson.OFFICE, title: formatMessage({ id: 'taskType.office', defaultMessage: 'Office' }) },
      { id: CustomFieldPermisson.JOB_TITLE, title: formatMessage({ id: 'taskType.jobTitle', defaultMessage: 'Job Title' }) },
      { id: CustomFieldPermisson.PROJECT_GROUP, title: formatMessage({ id: 'taskType.groupProject', defaultMessage: 'Project Group' }) },
      { id: CustomFieldPermisson.DATE, title: formatMessage({ id: 'taskType.date', defaultMessage: 'Date' }) },
      { id: CustomFieldPermisson.STRING, title: formatMessage({ id: 'taskType.string', defaultMessage: 'String' }) },
      { id: CustomFieldPermisson.NUMBER, title: formatMessage({ id: 'taskType.number', defaultMessage: 'Number' }) },
      { id: CustomFieldPermisson.EDITOR, title: formatMessage({ id: 'taskType.editor', defaultMessage: 'Editor' }) },
      { id: CustomFieldPermisson.TaskTypesInArray, title: formatMessage({ id: 'taskType.list', defaultMessage: 'List' }) },
      { id: CustomFieldPermisson.Risks, title: formatMessage({ id: 'taskType.risk', defaultMessage: 'Risks' }) },
      { id: CustomFieldPermisson.IMAGES, title: formatMessage({ id: 'taskType.image', defaultMessage: 'Image' }) },

    ];

    const sortedOptions = taskType.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (intl.locale === 'sv') {
        return titleA.localeCompare(titleB, 'sv');
      } else {
        return titleA.localeCompare(titleB, 'en');
      }
    });

    setTaskTypes(sortedOptions);
  }, [intl]);

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [nameTypeSv, setNameTypeSv] = useState('');
  const [nameTypeEn, setNameTypeEn] = useState('');
  
  const [optionsDropdown, setOptionsDropdown] = useState<any>("");
  const [isInput, setIsInput] = useState(false);
  const [isOutput, setIsOutput] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [currentMode, setCurrentMode] = useState('');
  const [listItems, setListItems] = useState<any>([]);
  const [confirmationModalDraft, setConfirmationModalDraft] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [sortOrder, setSortOrder] = useState<any>('');
  
 
  const { data: departmentsData } = useGetAllDepartmentsSimpleQuery({});
  const { data: officesData } = useGetAllOfficesSimpleQuery({});
  const { data: usersData } = useGetAllUsersQuery({});
  const { data: jobTitlesData } = useGetAllJobTitlesSimpleQuery({});
  const { data: projectGroupsData } = useGetAllProjectGroupsSimpleQuery({});
  const { data: rolesCompaniesData } = useGetRolesCompanyNoIdQuery({});

  const departments = departmentsData?.data || [];
  const offices = officesData?.data || [];
  const users = usersData?.data || [];
  const jobTitles = jobTitlesData?.data || [];
  const projectGroups = projectGroupsData?.data || [];
  const roles = rolesCompaniesData?.data || [];


  const [createTaskType] = useCreateTaskTypeMutation();
  const [updateTaskType] = useUpdateTaskTypeMutation();

  const { data: taskType, error, isLoading, refetch } = useGetOneTaskTypeQuery({ id: taskTypeId, update: true }, { skip: mode === 'create' });

  const customFieldRender: any = customFieldCreated;

  const [draftStatus, setDraftStatus] = useState(mode === 'edit' ? taskType?.data?.is_draft : false);

  useEffect(() => {
    return () => {
      dispatch(setCustomFieldCreated([]));
      localStorage.removeItem('uuids');
      localStorage.removeItem('uuids2');
      localStorage.removeItem('uuidCustomField');
      localStorage.removeItem('frontId');
      localStorage.removeItem('frontId_content');
    };
  }, []);


  useEffect(() => {
    if (mode === 'edit' && taskType?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs))
    }
  }, [taskType?.data?.entity_files]);

  useEffect(() => {
    if (mode === 'edit' && taskType?.data?.custom_fields?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType?.data?.custom_fields
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [taskType?.data?.custom_fields]);

  const initialValues = {
    title: taskType?.data.name || '',
    description: taskType?.data.description || '',
    is_draft: taskType?.data.is_draft || false,
    contentMain: taskType?.data.content || '',
    is_risk: taskType?.data.is_risk || false,
    is_simplified: taskType?.data.is_simplified || false,
    content: taskType?.data?.custom_fields?.find((field: any) => field.field_type === CustomFieldPermisson.EDITOR)?.editor || '',
    allUsers: taskType?.data.is_company_wide_access || false,
    is_mandatory: taskType?.data.is_mandatory || false,
    departments: taskType?.data.accesses?.departments?.map((dept: any) => dept.id) || [],
    offices: taskType?.data.accesses?.offices?.map((office: any) => office.id) || [],
    job_titles: taskType?.data.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [],
    project_groups: taskType?.data.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [],
    users: taskType?.data.accesses?.users?.map((user: any) => user.id) || [],
    roles: taskType?.data?.accesses?.roles?.map((role: any) => role.id) || [], 
  };
  
  useEffect(() => {
    if (mode === 'edit' && taskTypeId) {
      refetch();
    }
  }, [mode, taskTypeId]);

  useEffect(() => {
    if (mode === 'edit' && taskType?.data?.custom_fields) {
      dispatch(setCustomFieldCreated(taskType.data.custom_fields));
    }
  }, [mode, taskType?.data?.custom_fields, dispatch]);

  useEffect(() => {
    if (departmentsData) {
      const tree = transformToTree(departmentsData.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departmentsData]);

  const isEditor = (type: any) => {
    return [
      CustomFieldPermisson.EDITOR,
    ].includes(type?.value);
  };

  const isDescriptionRequired = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
    ].includes(type?.value);
  };

  const isMultiselect = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
      CustomFieldPermisson.DEPARTMENT,
      CustomFieldPermisson.PROJECT_GROUP,
      CustomFieldPermisson.JOB_TITLE,
      CustomFieldPermisson.OFFICE,
      CustomFieldPermisson.USER,
    ].includes(type?.value);
  };

  const taskTypesList = (type: any) => {
    return [
      CustomFieldPermisson.TaskTypesInArray,
    ].includes(type?.value);
  }

  
  const generateUniqueId = () => {
    let uniqueId: any;
    do {
      uniqueId = Math.random().toString(36).slice(2, 10);
    } while (customFieldRender.some((customField: any) => customField.id === uniqueId));
    return "tempID" + uniqueId;
  };

  const handleConfirm = (values: any) => {  
    const id = selectedItem?.id || generateUniqueId()
    const newCustomField = {
      id: id,
      field_type: selectedType?.value,
      is_input: isInput,
      is_output: isOutput,
      ...isMultiselect(selectedType) && {
        is_multiselect: isMulti,
      },
      is_required: isRequired,
      translations: [
      { language_code: 'en', translated_name: selectedType?.value === CustomFieldPermisson.Risks ? 'Risk' : nameTypeEn },
      { language_code: 'sv', translated_name: selectedType?.value === CustomFieldPermisson.Risks ? 'Risk': nameTypeSv },
      ],
      ...isEditor(selectedType) && {
        editor: values.content,
      },
      ...(isDescriptionRequired(selectedType) && {
        options: optionsDropdown
      }), 
      ...taskTypesList(selectedType) && {
        list_items: listItems.map((item: any) => ({
          ...item,
          parent_id: id,
        })),
      },
      ...(selectedType?.value === CustomFieldPermisson.Risks && {
        risk_items: listItems.map((item: any) => ({
          ...item,
          parent_id: id,
        })),
      }),
    };
    if (selectedItem?.id) {
      const updatedCustomField = customFieldCreated.map((customField: any) =>
        customField.id === selectedItem.id ? { ...customField, ...newCustomField } : customField
      );
      dispatch(setCustomFieldCreated(updatedCustomField));
    } else  {
      dispatch(setCustomFieldCreated([...customFieldCreated, newCustomField]));
    }
      setConfirmationModal(false);
      setNameTypeEn('');
      setNameTypeSv('');
      setOptionsDropdown('');
      setIsInput(false);
      setIsOutput(false);
      setIsMulti(false);
      setIsRequired(false);
      setSelectedType(null);
      setSelectedItem(null);
      setListItems([]);
      setCurrentMode('');
      saveContentFileBack();
  }

  function handleCancel() {
    setNameTypeEn('');
    setNameTypeSv('');
    setOptionsDropdown('');
    setCurrentMode('');
    setIsInput(false);
    setIsOutput(false);
    setIsMulti(false);
    setIsRequired(false);
    setSelectedType(null);
    setSelectedItem(null);
    setConfirmationModal(false);
    setListItems([]);
    saveContentFileBack();
  }

  function handleCancelView() {
    setNameTypeEn('');
    setNameTypeSv('');
    setOptionsDropdown('');
    setIsInput(false);
    setIsOutput(false);
    setIsMulti(false);
    setIsRequired(false);
    setSelectedType(null);
    setSelectedItem(null);
    setOpenViewModal(false);
  }

  const viewModal = (item: any) => {
    setSelectedItem(item);
    const matchingItems = customFieldRender.filter((customField: any) => customField.id === item.id);
    setIsInput(matchingItems[0].is_input);
    setIsOutput(matchingItems[0].is_output);
    setIsMulti(matchingItems[0].is_multiselect);
    setIsRequired(matchingItems[0].is_required);
    setNameTypeEn(matchingItems[0].translations.find((t: any) => t.language_code === 'en').translated_name);
    setNameTypeSv(matchingItems[0].translations.find((t: any) => t.language_code === 'sv').translated_name);
    setSelectedType(matchingItems[0].field_type);
    const findField = taskTypes.find((taskType: any) => taskType.id === matchingItems[0].field_type);
    if (findField) {
      const updatedField = { value: findField.id, label: findField.title };
      setSelectedType(updatedField as TaskType);
    }
    if (matchingItems[0].options) {      
      setOptionsDropdown(matchingItems[0].options.map((item: any) => item.translations))
    }
    setCurrentMode('edit');
    setOpenViewModal(true);
  };

  const createCustomField = () => {
    setCurrentMode('create');
    setConfirmationModal(true);
    saveContentFile();

  }

  const handleEditClick = (item: any) => {
    setSelectedItem(item);
    const matchingItems = customFieldRender.filter((customField: any) => customField.id === item.id);
    if(matchingItems[0].list_items) {
    const filteredListItems = matchingItems[0].list_items.filter((item: any) => item.parent_id === matchingItems[0].id);
      setListItems(filteredListItems);
    }
    if(matchingItems[0].risk_items) {
      const filteredListItems = matchingItems[0].risk_items.filter((item: any) => item.parent_id === matchingItems[0].id);
      setListItems(filteredListItems);
    }
    setIsInput(matchingItems[0].is_input);
    setIsOutput(matchingItems[0].is_output);
    setIsMulti(matchingItems[0].is_multiselect);
    setIsRequired(matchingItems[0].is_required);
    setNameTypeEn(matchingItems[0].translations.find((t: any) => t.language_code === 'en').translated_name);
    setNameTypeSv(matchingItems[0].translations.find((t: any) => t.language_code === 'sv').translated_name);
    setSelectedType(matchingItems[0].field_type);
    const findField = taskTypes.find((taskType: any) => taskType.id === matchingItems[0].field_type);

    if (findField) {
      const updatedField = { value: findField.id, label: findField.title };
      setSelectedType(updatedField as TaskType);
    }

    if (matchingItems[0].options) {   
      setOptionsDropdown(matchingItems[0].options)
    }
    setConfirmationModal(true);
    saveContentFile();
  };

  const handleDeleteId = (item: any) => {
    const updatedCustomField = customFieldRender.filter((customField: any) => customField.id !== item.id);
    dispatch(setCustomFieldCreated(updatedCustomField));
  };


  const handleSubmit = async (values: any, draft: any) => {
    try {
      const backUID = localStorage.getItem('uuidCustomField');
      const frontUID = localStorage.getItem('frontId_content');

      const backUID2Main = localStorage.getItem('uuids2')
      const backUIDMain = localStorage.getItem('uuids');
      const frontUIDMain = localStorage.getItem('frontId');
      
      let data: any = {
        name: values.title,
        description: values.description,
        content: values.contentMain,
        is_risk: values.is_risk ? 1 : 0,
        custom_fields: customFieldCreated?.map((customField: any) => {
          let updatedCustomField = { ...customField};
          if (customField.options && customField?.options?.some((option: any) => option.option_value)) {
            const updatedOptions = customField?.options?.map((option: any) => ({
              ...option,
              value: option.option_value || option.value,
            }));
            updatedCustomField = {
              ...updatedCustomField,
              options: updatedOptions,
            };
          }
          if (typeof customField.id === 'string' && customField.id.startsWith('tempID'))  {
              delete updatedCustomField.id;
          }
          if (customField.field_type === CustomFieldPermisson.TaskTypesInArray && customField.list_items) {
            updatedCustomField.list_items = customField.list_items.map((item: any) => {
              if (item.id && typeof item.id === 'string' && item.id.startsWith('tempID')) {
                const { id, parent_id, ...rest } = item; 
                item = rest; // Update the item to exclude `id`
              }
          
              // If item contains options, process them to remove temp IDs
              if (item?.field_type === CustomFieldPermisson.DROPDOWN && item.options) {
                const updatedOptions = item.options.map((option: any) => {
                  if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                    const { id, ...rest } = option; // Exclude `id`
                    return rest; // Return the new object without `id`
                  }
                  return option; // Return the original option if no changes
                });
                item = { ...item, options: updatedOptions }; // Update item with new options
              }
          
              return item; // Return the modified or original item
            });
          }
                console.log(customField.field_type == CustomFieldPermisson.Risks )
          if (customField.field_type === CustomFieldPermisson.Risks && customField.risk_items) {
            updatedCustomField.risk_items = customField.risk_items.map((item: any) => {
              if (item.id && typeof item.id === 'string' && item.id.startsWith('tempID')) {
                const { id, parent_id, ...rest } = item; 
                item = rest; // Update the item to exclude `id`
              }
          
              // If item contains options, process them to remove temp IDs
              if (item?.field_type === CustomFieldPermisson.DROPDOWN && item.options) {
                const updatedOptions = item.options.map((option: any) => {
                  if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                    const { id, ...rest } = option; // Exclude `id`
                    return rest; // Return the new object without `id`
                  }
                  return option; // Return the original option if no changes
                });
                item = { ...item, options: updatedOptions }; // Update item with new options
              }
          
              return item; // Return the modified or original item
            });
          }
          
          if (customField.field_type === CustomFieldPermisson.DROPDOWN && customField.options) {
            updatedCustomField.options = customField.options.map((option: any) => {
              if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                const { id, ...rest } = option; // Destructure to exclude `id`
                return rest; // Return the new object without `id`
              }
              return option;
            });
          }         
          if (customField.field_type === CustomFieldPermisson.EDITOR && backUID) {
            try {
              const parsedBackUID = JSON.parse(backUID);
              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                updatedCustomField = {
                  ...updatedCustomField,
                  entity_files: {
                    uuid: frontUID || uuidv4(),
                    files: parsedBackUID.map((id: any) => ({
                      id,
                      tag: ""
                    })),
                  },
                };
              }
            } catch (error) {
              console.error('Failed to parse backUID:', error);
            }
          }
          return updatedCustomField;
        }),
        is_draft: draft,
        is_simplified: values.is_simplified,
        is_company_wide_access: values.allUsers ? 1 : 0,
        access: {
          users: values.users,
          job_titles: values.job_titles,
          project_groups: values.project_groups,
          departments: values.departments,
          offices: values.offices,
          roles: values.roles,
        },
      };        

            if (backUIDMain || backUID2Main) {
              try {
                const parsedBackUID = JSON.parse(backUIDMain || backUID2Main || '[]');
                if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                  data = {
                    ...data,
                    entity_files: {
                      uuid: frontUIDMain || uuidv4(),
                      files: parsedBackUID.map((id: any) => ({
                        id,
                        tag: ""
                      })),
                    },
                  };
                }
              } catch (error) {
                console.error('Failed to parse backUID:', error);
              }
      }     
      data.status = 'published';
      if (mode === 'create') {
        await createTaskType({ data: data }).unwrap();
        navigate(paths.company);
        dispatch(setCustomFieldCreated([]));
      } else if (mode === 'edit' && taskTypeId) {
        await updateTaskType({ id: taskTypeId, data: data }).unwrap();
        dispatch(setCustomFieldCreated([]));
        navigate(paths.company);
      }
    } catch (error) {
      console.error('Failed to create/update taskType:', error);
    }
  };

  const saveContentFile = () => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const existingUUIDs2 = localStorage.getItem('uuids2');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuids2 = existingUUIDs2 ? JSON.parse(existingUUIDs2) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuids2])); // Use Set to ensure uniqueness
  
      localStorage.setItem('uuids2', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
  };

  const saveContentFileBack = () => {
    try {
      const existingUUIDs = localStorage.getItem('uuids');
      const uuidCustomField = localStorage.getItem('uuidCustomField');
      let uuids = existingUUIDs ? JSON.parse(existingUUIDs) : [];
      let uuidCustomField2 = uuidCustomField ? JSON.parse(uuidCustomField) : [];
      const updatedUUIDs = Array.from(new Set([...uuids, ...uuidCustomField2]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(updatedUUIDs));
      localStorage.removeItem('uuids');
    } catch (error) {
      console.error("Error setting 'uuids' in localStorage:", error);
    }
  }

  const moveCustomField = (dragIndex: number, hoverIndex: number) => {
    const updatedFields = [...customFieldRender];
    const [draggedItem] = updatedFields.splice(dragIndex, 1);
    updatedFields.splice(hoverIndex, 0, draggedItem);
    dispatch(setCustomFieldCreated(updatedFields));
};

  
  const handleDragEnd = async () => {
     // drag add end functionality maybe refetch or send data to abckend
  };

  const handleSortAZ = async (values: any) => {
    // Sort and get sorted array immediately
    const sortedFields = [...customFieldRender].sort((a, b) => {
      const nameA = a.translations.find((t: any) => t.language_code === 'sv').translated_name.toLowerCase();
      const nameB = b.translations.find((t: any) => t.language_code === 'sv').translated_name.toLowerCase();
      return 'asc' === 'asc' ? nameA.localeCompare(nameB, 'sv') : nameB.localeCompare(nameA, 'sv');
    });
    // Update state
    dispatch(setCustomFieldCreated(sortedFields));
    setOpenSortModal(false);
  
    try {
      const backUID = localStorage.getItem('uuidCustomField');
      const frontUID = localStorage.getItem('frontId_content');

      const backUID2Main = localStorage.getItem('uuids2')
      const backUIDMain = localStorage.getItem('uuids');
      const frontUIDMain = localStorage.getItem('frontId');
      
      let data: any = {
        name: values.title,
        description: values.description,
        content: values.contentMain,
        is_risk: values.is_risk ? 1 : 0,
        custom_fields: sortedFields?.map((customField: any) => {
          let updatedCustomField = { ...customField};
          if (customField.options && customField?.options?.some((option: any) => option.option_value)) {
            const updatedOptions = customField?.options?.map((option: any) => ({
              ...option,
              value: option.option_value || option.value,
            }));
            updatedCustomField = {
              ...updatedCustomField,
              options: updatedOptions,
            };
          }
          if (typeof customField.id === 'string' && customField.id.startsWith('tempID'))  {
              delete updatedCustomField.id;
          }
          if (customField.field_type === CustomFieldPermisson.TaskTypesInArray && customField.list_items) {
            updatedCustomField.list_items = customField.list_items.map((item: any) => {
              if (item.id && typeof item.id === 'string' && item.id.startsWith('tempID')) {
                const { id, parent_id, ...rest } = item; 
                item = rest; // Update the item to exclude `id`
              }
          
              // If item contains options, process them to remove temp IDs
              if (item?.field_type === CustomFieldPermisson.DROPDOWN && item.options) {
                const updatedOptions = item.options.map((option: any) => {
                  if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                    const { id, ...rest } = option; // Exclude `id`
                    return rest; // Return the new object without `id`
                  }
                  return option; // Return the original option if no changes
                });
                item = { ...item, options: updatedOptions }; // Update item with new options
              }
          
              return item; // Return the modified or original item
            });
          }
          
          if (customField.field_type === CustomFieldPermisson.DROPDOWN && customField.options) {
            updatedCustomField.options = customField.options.map((option: any) => {
              if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                const { id, ...rest } = option; // Destructure to exclude `id`
                return rest; // Return the new object without `id`
              }
              return option;
            });
          }         
          if (customField.field_type === CustomFieldPermisson.EDITOR && backUID) {
            try {
              const parsedBackUID = JSON.parse(backUID);
              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                updatedCustomField = {
                  ...updatedCustomField,
                  entity_files: {
                    uuid: frontUID || uuidv4(),
                    files: parsedBackUID.map((id: any) => ({
                      id,
                      tag: ""
                    })),
                  },
                };
              }
            } catch (error) {
              console.error('Failed to parse backUID:', error);
            }
          }

  
          return updatedCustomField;
        }),
        is_draft: values.draft,
        is_simplified: values.is_simplified,
        is_company_wide_access: values.allUsers ? 1 : 0,
        access: {
          users: values.users,
          job_titles: values.job_titles,
          project_groups: values.project_groups,
          departments: values.departments,
          offices: values.offices,
          roles: values.roles,
        },
      };        

            if (backUIDMain || backUID2Main) {
              try {
                const parsedBackUID = JSON.parse(backUIDMain || backUID2Main || '[]');
                if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                  data = {
                    ...data,
                    entity_files: {
                      uuid: frontUIDMain || uuidv4(),
                      files: parsedBackUID.map((id: any) => ({
                        id,
                        tag: ""
                      })),
                    },
                  };
                }
              } catch (error) {
                console.error('Failed to parse backUID:', error);
              }
      }     
      data.status = 'published';
      if (mode === 'edit' && taskTypeId) {
        await updateTaskType({ id: taskTypeId, data: data }).unwrap();
      }
    } catch (error) {
      console.error('Failed to create/update taskType:', error);
    }
  };

  const handleSortZA = async (values: any) => {
    // Compute the sorted fields array for descending order locally
    const sortedFields = [...customFieldRender].sort((a, b) => {
      const nameA = a.translations.find((t: any) => t.language_code === 'sv').translated_name.toLowerCase();
      const nameB = b.translations.find((t: any) => t.language_code === 'sv').translated_name.toLowerCase();
      return nameB.localeCompare(nameA, 'sv');
    });
  
    // Optionally update the state for UI consistency
    dispatch(setCustomFieldCreated(sortedFields));
    setOpenSortModal(false);
  
    try {
      const backUID = localStorage.getItem('uuidCustomField');
      const frontUID = localStorage.getItem('frontId_content');

      const backUID2Main = localStorage.getItem('uuids2')
      const backUIDMain = localStorage.getItem('uuids');
      const frontUIDMain = localStorage.getItem('frontId');
      
      let data: any = {
        name: values.title,
        description: values.description,
        content: values.contentMain,
        is_risk: values.is_risk ? 1 : 0,
        custom_fields: sortedFields?.map((customField: any) => {
          let updatedCustomField = { ...customField};
          if (customField.options && customField?.options?.some((option: any) => option.option_value)) {
            const updatedOptions = customField?.options?.map((option: any) => ({
              ...option,
              value: option.option_value || option.value,
            }));
            updatedCustomField = {
              ...updatedCustomField,
              options: updatedOptions,
            };
          }
          if (typeof customField.id === 'string' && customField.id.startsWith('tempID'))  {
              delete updatedCustomField.id;
          }
          if (customField.field_type === CustomFieldPermisson.TaskTypesInArray && customField.list_items) {
            updatedCustomField.list_items = customField.list_items.map((item: any) => {
              if (item.id && typeof item.id === 'string' && item.id.startsWith('tempID')) {
                const { id, parent_id, ...rest } = item; 
                item = rest; // Update the item to exclude `id`
              }
          
              // If item contains options, process them to remove temp IDs
              if (item?.field_type === CustomFieldPermisson.DROPDOWN && item.options) {
                const updatedOptions = item.options.map((option: any) => {
                  if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                    const { id, ...rest } = option; // Exclude `id`
                    return rest; // Return the new object without `id`
                  }
                  return option; // Return the original option if no changes
                });
                item = { ...item, options: updatedOptions }; // Update item with new options
              }
          
              return item; // Return the modified or original item
            });
          }
          
          if (customField.field_type === CustomFieldPermisson.DROPDOWN && customField.options) {
            updatedCustomField.options = customField.options.map((option: any) => {
              if (typeof option.id === 'string' && option.id.startsWith('tempID')) {
                const { id, ...rest } = option; // Destructure to exclude `id`
                return rest; // Return the new object without `id`
              }
              return option;
            });
          }         
          if (customField.field_type === CustomFieldPermisson.EDITOR && backUID) {
            try {
              const parsedBackUID = JSON.parse(backUID);
              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                updatedCustomField = {
                  ...updatedCustomField,
                  entity_files: {
                    uuid: frontUID || uuidv4(),
                    files: parsedBackUID.map((id: any) => ({
                      id,
                      tag: ""
                    })),
                  },
                };
              }
            } catch (error) {
              console.error('Failed to parse backUID:', error);
            }
          }
         return updatedCustomField;
        }),
        is_draft: values.draft,
        is_simplified: values.is_simplified,
        is_company_wide_access: values.allUsers ? 1 : 0,
        access: {
          users: values.users,
          job_titles: values.job_titles,
          project_groups: values.project_groups,
          departments: values.departments,
          offices: values.offices,
          roles: values.roles,
        },
      };        

            if (backUIDMain || backUID2Main) {
              try {
                const parsedBackUID = JSON.parse(backUIDMain || backUID2Main || '[]');
                if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                  data = {
                    ...data,
                    entity_files: {
                      uuid: frontUIDMain || uuidv4(),
                      files: parsedBackUID.map((id: any) => ({
                        id,
                        tag: ""
                      })),
                    },
                  };
                }
              } catch (error) {
                console.error('Failed to parse backUID:', error);
              }
      }     
      data.status = 'published';
      if (mode === 'edit' && taskTypeId) {
        await updateTaskType({ id: taskTypeId, data: data }).unwrap();
      }
    } catch (error) {
      console.error('Failed to create/update taskType:', error);
    }
  };

  return (
  <DndProvider backend={HTML5Backend}>
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'taskType.edit', defaultMessage: 'Edit: ' }) + initialValues.title
                    : formatMessage({ id: 'taskType.create', defaultMessage: 'Create Task Type' })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => navigate(paths.company)}
                >
                  {formatMessage({ id: 'taskType.cancel', defaultMessage: 'Cancel' })}
                </Button>
                {!values.is_draft && mode ==='edit' ? null : (
                  <Button
                  appearance="secondary"
                  type="button"
                    onClick={() => {
                      { mode === 'create' ? setConfirmationModalDraft(true) : handleSubmit(values, true)}
                    }}
                >
                  {mode === 'create' ? formatMessage({ id: 'taskType.createAsDraft', defaultMessage: 'Create as draft' }) : formatMessage({ id: 'taskType.saveAsDraft', defaultMessage: 'Save as draft' })}
                    </Button>)}
                  
              <Button appearance="primary" type="button"
                  onClick={() => { handleSubmit(values, false) }}
                >
                  {mode === 'create'
                    ? formatMessage({ id: 'taskType.publish', defaultMessage: 'Publish' }) : mode === 'edit' && values.is_draft ? formatMessage({ id: 'taskType.savePublish', defaultMessage: 'Save and publish' }) :
                    formatMessage({ id: 'taskType.save', defaultMessage: 'Save' })}
                </Button> 
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <ColumnTopLevel style={{ zIndex: 9995}}>
                  <Row>
                    <FormikInput
                      label={formatMessage({ id: 'taskType.name', defaultMessage: 'Name' })}
                      name="title"
                      placeholder={formatMessage({ id: 'taskType.placeholderTaskTypeTitle', defaultMessage: 'Enter a taskType title' })}
                      autoComplete="off"
                      required
                    />
                  </Row> 
                  <Row>
                  <FormikTextarea
                    label={formatMessage({ id: 'taskType.description', defaultMessage: 'Description' })}
                    name="description"
                    placeholder={formatMessage({ id: 'taskType.placeholderTaskTypeDescriptionr', defaultMessage: 'Enter a library description' })}
                    autoComplete="off"
                    required
                  />
                  </Row>
                  <WangEditorMain name='contentMain' />
                  <Button appearance='primary' style={{width: '10%', marginTop: 10}} onClick={saveContentFile}>{formatMessage({
                      id: 'investigationForm.save',
                      defaultMessage: 'Save'
                    })}</Button> 
                  
                </ColumnTopLevel>
              </FormContent>
            </TabContent>
            <Column style={{ marginTop: 40 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>               
                  <FormikCheckbox
                    label={formatMessage({ id: 'handbookForm.checkBox', defaultMessage: 'All users' })}
                    name="allUsers"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = e;
                      setFieldValue('allUsers', isChecked);
                      if (isChecked) {
                        setFieldValue('departments', []);
                        setFieldValue('offices', []);
                        setFieldValue('job_titles', []);
                        setFieldValue('project_groups', []);
                        setFieldValue('users', []);
                        setFieldValue('roles', []);
                      }
                    }}
                  />
                  <FormikCheckbox
                  label={formatMessage({ id: 'taskType.is_simplified', defaultMessage: 'Is simplified task type' })}
                  name="is_simplified"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isChecked = e;
                    setFieldValue('is_simplified', isChecked);                      
                  }}
                  />             
                </div>
                {!values.allUsers && (     
                    <>
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.departmentLabel', defaultMessage: 'Department/Business Unit/Team' })}
                        name="departments"
                        options={departmentTree}
                        values={values.departments}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.officeLabel', defaultMessage: 'Office' })}
                        name="offices"
                        options={offices.map((office: any) => ({ value: office.id, label: office.name }))}
                        values={values.offices}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.jobTitleLabel', defaultMessage: 'Job Title' })}
                        name="job_titles"
                        options={jobTitles.map((jobTitle: any) => ({ value: jobTitle.id, label: jobTitle.name }))}
                        values={values.job_titles}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.groupProjectLabel', defaultMessage: 'Project group' })}
                        name="project_groups"
                        options={projectGroups.map((projectGroup: any) => ({ value: projectGroup.id, label: projectGroup.name }))}
                        values={values.project_groups}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.userLabel', defaultMessage: 'User' })}
                        name="users"
                        options={users.map((user: any) => ({ value: user.id, label: user.fullname }))}
                        values={values.users}
                        onChange={setFieldValue}
                      />
                     <MultiSelect
                        label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
                        name="roles"
                        options={roles.map((role: any) => ({ value: role.id, label: `${role.title}` })) || []}
                        values={values.roles}
                        onChange={(field, value) => {
                        setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? [] : value)}}
                      />
                    </>
                  )}
            </Column>
            <Header>
              <ButtonSecondary type="button" style={{ marginTop: 20, alignSelf: 'flex-end' }} onClick={() => { createCustomField() }}>
                 <LuPlusCircle size={20} />
                 <HideOnMobile>
                   {formatMessage({ id: 'taskType.addCustomField', defaultMessage: 'Add custom field' })}
                 </HideOnMobile>
              </ButtonSecondary>
            </Header>
         {customFieldRender.length > 0 && (   
            <ListContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{formatMessage({ id: 'task.fieldName', defaultMessage: 'Field Name' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.typeField', defaultMessage: 'Type Field' })}</Th> 
                    <Th>{formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is Multiselect' })}</Th>
                    <Th>{formatMessage({ id: 'taskType.actions', defaultMessage: 'Actions' })}</Th>  
                        <Th>
                           <div className="dropdown-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , cursor: 'pointer', backgroundColor: openSortModal ? 'lightGrey' : 'transparent', borderRadius: 5 }} onClick={() => setOpenSortModal(!openSortModal)}>
                               <RiExpandUpDownFill size={25} />
                             </div>
                          {openSortModal && <div
                            className="dropdown-container"
                            style={{
                            position: 'absolute', right: '2%', marginTop: 5, backgroundColor: 'white',
                            borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)',
                            zIndex: 9999
                          }}>
                            <DropdownOption
                              sortOrder={'asc'}
                              selected={sortOrder === 'asc'}
                              onClick={() => handleSortAZ(values)}
                              label={formatMessage({ id: 'taskType.sortByA', defaultMessage: 'Sort by A-Z' })}
                            />
                            <DropdownOption
                              sortOrder={'desc'}
                              selected={sortOrder === 'desc'}
                              onClick={() => handleSortZA(values)}
                              label={formatMessage({ id: 'taskType.sortByZ', defaultMessage: 'Sort by Z-A' })}
                            />
                          </div>}
                        </Th>    
                  </Tr>
                </Thead>
            <Tbody>
                {customFieldRender.map((item: any, index: number) => (
                  <>
                    <DragableCustomField
                      key={item.id}
                      item={item}
                      index={index}
                      isMultiselect={isMultiselect}
                      handleEditClick={handleEditClick}
                      handleDeleteId={handleDeleteId}
                      viewModal={viewModal}
                      intl={intl}
                      moveCustomField={moveCustomField}
                      onDragEnd={handleDragEnd}
                      isRisk={item?.field_type === CustomFieldPermisson.Risks}
                    />
                  </>
              )
                  )}
            </Tbody>
              </Table>
            </ListContainer>
            )}
           {confirmationModal && (
            <ConfirmationModal
              header={<ModalConent mode={currentMode} taskTypes={taskTypes} selectedType={selectedType} setSelectedType={setSelectedType}
              nameTypeEn={nameTypeEn} setNameTypeEn={setNameTypeEn} nameTypeSv={nameTypeSv} setNameTypeSv={setNameTypeSv} optionsDropdown={optionsDropdown} setOptionsDropdown={setOptionsDropdown}
              isInput={isInput} setIsInput={setIsInput} isOutput={isOutput} setIsOutput={setIsOutput} isRequired={isRequired} setIsRequired={setIsRequired} isMulti={isMulti} setIsMulti={setIsMulti}
              listItems={listItems} setListItems={setListItems} Editor={<WangEditor name='content'/>}
            />}
              onConfirm={()=> {handleConfirm(values)}}
              onCancel={handleCancel}
             />
      )}
      
      {openViewModal && (
        <NoticeModal
        header={<ModalContentView taskTypes={taskTypes} selectedType={selectedType} setSelectedType={setSelectedType}
        nameTypeEn={nameTypeEn} setNameTypeEn={setNameTypeEn} nameTypeSv={nameTypeSv} setNameTypeSv={setNameTypeSv} optionsDropdown={optionsDropdown}
        isInput={isInput} setIsInput={setIsInput} isOutput={isOutput} setIsOutput={setIsOutput} isRequired={isRequired} setIsRequired={setIsRequired} isMulti={isMulti} setIsMulti={setIsMulti}
        />}
        onConfirm={handleCancelView}
      />
            )}
            
            {confirmationModalDraft && (
              <ConfirmationModal
                header={formatMessage({ id: 'taskType.draftSentence', defaultMessage: 'This task type will be created as draft, are you sure you wanna continue?' })}
                onConfirm={() => {
                  setConfirmationModalDraft(false)
                  handleSubmit(values, true)
                }}
                onCancel={() => {
                  setDraftStatus(false)
                  setConfirmationModalDraft(false)
                }}
              />)}

          </Form>
        )} 
      
      </Formik>
      </FormPaper>
    </DndProvider>
  );
};

export default TaskTypeForm;