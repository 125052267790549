import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LuPlusCircle } from 'react-icons/lu';
import  ButtonSecondary from '../../Button/ui/components/ButtonSecondary';
import { HideOnMobile } from '../../../components/BaseLayout/BaseLayout.styles';
import { setTasktypeId } from '../../../slices/authSlice';
import { paths } from '../../../config/paths';

interface ButtonForTaskProps {
    data: {
        task_type_id: number;
        btn_text: string;
    };
}

const ButtonForTask: React.FC<ButtonForTaskProps> = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <ButtonSecondary
                onClick={() => {
                    dispatch(setTasktypeId(data?.task_type_id));
                    navigate(paths.createTask);
                }}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <LuPlusCircle />
                <HideOnMobile>
                    {data?.btn_text}
                </HideOnMobile>
            </ButtonSecondary>
        </div>
    );
};

export default ButtonForTask;