import React, { useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { joinUrl } from '../../../ui-kit/utils/url';
import { setCredentials, setPermissions } from '../../../slices/authSlice'
import {
  useGetUserInfoQuery, useGetUserPermissionsQuery,
  useLoginMutation,
  useRecoverPasswordMutation,
} from '../../../slices/usersApiSlices'
import { setMethod, setLocale } from '../../../slices/settingsSlice';
import { Form, FormikInput, Button } from '../../../ui-kit/';
import { loginSchema } from './Login.validation';
import { LoginResponse, UserInfo } from '../../../interfaces/auth';
import {
  Buttons,
  FormContainer,
  LoginContainer,
  Logo,
  Title,
  LinkText,
  Message,
} from './Login.styles';
import MicrosoftLogo from './MicrosoftLogo'
import { MdOutlineEmail } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";
import { formatMessage } from '../../../locale'


const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const myParam = searchParams.get('sso-error');

  useEffect(() => {
    if (myParam) {
      setErrorMessage(myParam === 'not_found' ? 'Not Found' : 'Server error connecting to Azure');
    }
  }, [myParam]);

  const [withPassword, setWithPassword] = useState(false);
  const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [login, { isLoading }] = useLoginMutation();
  const [recoverPassword, { isLoading: isRecovering }] = useRecoverPasswordMutation();

  const [shouldFetch, setShouldFetch] = useState(false);

  const {
    data: user,
    error: userInfoError,
    refetch: refetchUser,
  } = useGetUserInfoQuery({}, {  });

  const {
    refetch: refetchPermissions,
  } = useGetUserPermissionsQuery({}, {  });


  useEffect(() => {
    if (user) {
      dispatch(setCredentials(user as UserInfo));
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  }, [user, dispatch, navigate]);

  useEffect(() => {
    if (userInfoError) {
      console.error('Fetching user info error:', userInfoError);
    }
  }, [userInfoError]);

  const handleSubmit = async (values: LoginResponse) => {
    dispatch(setMethod('POST'));
    dispatch(setLocale('en'));

    try {
      const res = (await login({ ...values, email: values.email.trim().toLowerCase() }).unwrap()) as UserInfo;
      setShouldFetch(true);
      const res2 = (await refetchUser().unwrap()) as UserInfo;
      const permissions = await refetchPermissions().unwrap();
      dispatch(setCredentials({ ...res2 }));
      dispatch(setPermissions(permissions));
      window.dispatchEvent(new Event('userLoggedIn'));
      navigate('/dashboard');
    } catch (err: any) {
      handleErrors(err);
    }
  };

  const handleRecoverPassword = async (values: { email: string }) => {
    try {
      await recoverPassword({email: values.email.trim().toLowerCase()}).unwrap();
      setSuccessMessage('Password recovery email was sent');
      setErrorMessage('');
    } catch (err: any) {
      setSuccessMessage('');
      handleErrors(err);
    }
  };

  const handleErrors = (err: any) => {
    if (err.status === 401) {
      setErrorMessage(err?.data?.message || 'Unauthorized');
    } else if (err.status === 404) {
      setErrorMessage(err?.data?.message || 'Not Found');
    } else if (err.status === 500) {
      setErrorMessage(err?.data?.message || 'Server Error');
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  };
  
  return (
    <LoginContainer>
      {withPassword && (
        <FormContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
            <button onClick={() => setWithPassword(false)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <IoChevronBack size={35} style={{ background: 'none', alignSelf: 'flex-start', marginBottom: '1rem'}} />
            </button>
            <Logo>
              <img src="/assets/logo.svg" alt="Logo" />
            </Logo>
            <button disabled={true} style={{ visibility: 'hidden' }}>
              <IoChevronBack size={35} style={{ background: 'none', alignSelf: 'flex-start', marginBottom: '1rem' }} />
            </button>
          </div>
          <Title>{isRecoveringPassword ? 
            formatMessage({ id: 'loginPage.recoverPassword', defaultMessage: 'Recover Password' }) :
            formatMessage({ id: 'loginPage.login', defaultMessage: 'Log in' })
            }</Title>
          {isRecoveringPassword ? (
            <Form onSubmit={handleRecoverPassword}>
              <FormikInput
                label={formatMessage({ id: 'loginPage.yourEmail', defaultMessage: 'Your email' })}
                name="email"
                placeholder={formatMessage({ id: 'loginPage.email', defaultMessage: 'Email' })}
                autoComplete="off"
                required
              />
              {errorMessage && <Message error>{errorMessage}</Message>}
              {successMessage && <Message success>{successMessage}</Message>}
              <Buttons>
                <Button type="submit" disabled={isRecovering}>
                  {isRecovering ? 
                  formatMessage({ id: 'loginPage.sending', defaultMessage: 'Sending...' }) :
                  formatMessage({ id: 'loginPage.sendRecoveryEmail', defaultMessage: 'Send Recovery Email' })
                  }
                </Button>
              </Buttons>
              <LinkText onClick={() => setIsRecoveringPassword(false)}>
                {formatMessage({ id: 'loginPage.backToLogin', defaultMessage: 'Back to login' })}
              </LinkText>
            </Form>
          ) : (
            <Form onSubmit={handleSubmit} validationSchema={loginSchema}>
              <FormikInput
                label={formatMessage({ id: 'loginPage.yourEmail', defaultMessage: 'Your email' })}
                name="email"
                placeholder={formatMessage({ id: 'loginPage.email', defaultMessage: 'Email' })}
                autoComplete="off"
                required
              />
              <FormikInput
                label={formatMessage({ id: 'loginPage.yourPassword', defaultMessage: 'Your password' })}
                name="password"
                type="password"
                placeholder={formatMessage({ id: 'loginPage.password', defaultMessage: 'Password' })}
                autoComplete="off"
                required
              />
              {errorMessage && <Message error>{errorMessage}</Message>}
              <Buttons>
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? 
                  formatMessage({ id: 'loginPage.loading', defaultMessage: 'Loading...' })
                  : formatMessage({ id: 'loginPage.login', defaultMessage: 'Log in' }) }
                </Button>
              </Buttons>
              <LinkText onClick={() => setIsRecoveringPassword(true)}>
                {formatMessage({ id: 'loginPage.forgotPassword', defaultMessage: 'Forgot password?' })}
              </LinkText>

            </Form>
          )}
        </FormContainer>
      )}
      {!withPassword && (
        <FormContainer style={{ position: 'absolute', zIndex: 9999 }}>
          <Logo>
            <img src="/assets/logo.svg" alt="Logo" />
          </Logo>
          <Title style={{ marginTop: '2rem' }}>
            {formatMessage({ id: 'loginPage.login', defaultMessage: 'Log in' })}
          </Title>
          <Buttons>
            <Button
              type="button"
              appearance='primary'
              style={{ width: '68%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'left', }}
              onClick={() => {
                document.location = joinUrl(process.env?.REACT_APP_URL, 'login-azure')
              }}
            >
                <MicrosoftLogo/>
                {formatMessage({ id: 'loginPage.loginMicrosoft', defaultMessage: 'Log in with Microsoft' })}
            </Button>
          </Buttons>        
          <Buttons style={{marginBottom: '1rem'}}>
            <Button
              type="button"
              style={{ width: '68%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
              appearance='secondary' onClick={() => {
                setWithPassword(true)
              }}
            >
            <MdOutlineEmail size={25} style={{ alignSelf: 'center' }} />
              {formatMessage({ id: 'loginPage.loginEmailPassword', defaultMessage: 'Email & Password' })}
            </Button>
          </Buttons>         
        </FormContainer> 
      )}
    </LoginContainer>
  );
};

export default Login;
