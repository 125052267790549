import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const surveyFormApiSlice = createApi({
  reducerPath: 'surveyFormApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL || 'http://localhost',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSurveyForm: builder.query({
      query: ({ id }) => {
        return {
          url: `/api/v1/surveys/${id}`,
          method: 'GET',
        };
      },
    }),
    submitSurveyForm: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/surveys/${id}/answer`,
        method: 'POST',
        body: data,
      }),
      extraOptions: {
        useErrorHandling: false,
      },
    }),
  }),
});

export const { useGetSurveyFormQuery, useSubmitSurveyFormMutation } = surveyFormApiSlice;
