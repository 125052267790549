import { create, update } from 'lodash';
import { apiSlice, convertToFormData } from './apiSlice';

export const surveyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        surveyGetAll: builder.query({
            query: ({ search = '', page = 1, statusPaginated, startDate, endDate }) => {
                const params = new URLSearchParams();
                if (search) {
                    params.append('search', encodeURIComponent(search));
                }

                params.append('page', page.toString());

                if (statusPaginated.length > 0) {
                    statusPaginated.forEach((status: any) => {
                      params.append('status[]', status?.value);
                    });
                }

                if (startDate) {
                    const date = new Date(startDate);
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    const formattedStartDate = date.toISOString().split('T')[0];
                    params.append('started_at', formattedStartDate);
                }

                if (endDate) {
                    const date = new Date(endDate);
                    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    const formattedStartDate = date.toISOString().split('T')[0];
                    params.append('expired_at', formattedStartDate);
                }
                

                const queryString = params.toString()

                return {
                    url: `/api/v1/admin/surveys/?${queryString}`,
                    method: 'GET',
                }},
        }),
        createSurvey: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/admin/surveys`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        updateSurvey: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}?_method=PUT`,
                    method: 'POST',
                    body: convertToFormData(data)
                };
            },
        }),
        copySurvey: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/copy`,
                    method: 'POST',
                    body: convertToFormData(data)
                };
            },
        }),
        getSurvey: builder.query({
            query: ({id, update = false}) => {
                return {
                    url: `/api/v1/admin/surveys/${id}`  +  (update ? '?view=update' : ''),
                    method: 'GET',
                }},
        }),
        deleteSurvey: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/v1/admin/surveys/${id}`,
                    method: 'DELETE',
                }},
        }),
        //göra ändringart sen
        updateSurveyPublish: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${Number(id)}/publish`,
                    method: 'POST',
                }},
        }),
        toDraftSurvey: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/draft`,
                    method: 'POST',
                }},
        }),
        updateSurveyStart: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/start?_method=put`,
                    method: 'POST',
                }},
        }),
        updateSurveyParticipants: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/participants?_method=PUT`,
                    method: 'POST',
                    body: convertToFormData(data)
                }
            },
        }),
        updateSurveySchedule: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/schedule?_method=PUT`,
                    method: 'POST',
                    body: convertToFormData(data)
                }},
        }),
        copySurveyToOtherCompany: builder.mutation({
            query: ({ id, companyId }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/companies/${companyId}/copy`,
                    method: 'POST'
                }},
        }),
        createSurveyQuestion: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/questions`,
                    method: 'POST',
                    body: convertToFormData(data)
                }},
        }),
        updateSurveyQuestion: builder.mutation({
            query: ({ id, questionId, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/questions/${questionId}?_method=patch`,
                    method: 'POST',
                    body: convertToFormData(data)
                }},
        }),
        getOneSurveyQuestion: builder.query({
            query: ({ id, questionId }) => {
                console.log('id', id, 'questionId', questionId);
                return {
                    url: `/api/v1/admin/surveys/${id}/questions/${questionId}`,
                    method: 'GET',
                }},
        }),
        deleteSurveyQuestion: builder.mutation({
            query: ({ id, questionId }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/questions/${questionId}`,
                    method: 'DELETE',
                }}
        }),
        sortServeyQuestions: builder.mutation({
            query: ({ id, questionsIds }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/questions/sort?_method=put`,
                    method: 'POST',
                    body: {questions:questionsIds}
                }}
        }),
        getSurveyProgress: builder.query({
            query: ({ id, search = '', page = 1 }) => {
                const params = new URLSearchParams();
                if (search) {
                    params.append('search', encodeURIComponent(search));
                }

                params.append('page', page.toString());

                const queryString = params.toString().replace(/%5B%5D/g, '[]');

                return {
                    url: `/api/v1/admin/surveys/${id}/survey-progresses/?${queryString}`,
                    method: 'GET',
                }},
        }),
        exportSurveyResults: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/export`,
                    method: 'POST',
                    responseHandler: (response) => {
                        return response.blob();
                    }
                }
            }
        }),
        updateSurveyReoccurrence: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/api/v1/admin/surveys/${id}/recurring?_method=put`,
                    method: 'POST',
                    body: convertToFormData(data)
                };
            },
        }),
    }),
});

export const {
    useSurveyGetAllQuery,
    useGetSurveyQuery,
    useDeleteSurveyMutation,
    useUpdateSurveyPublishMutation,
    useToDraftSurveyMutation,
    useCreateSurveyMutation,
    useUpdateSurveyMutation,
    useCopySurveyMutation,
    useUpdateSurveyStartMutation,
    useUpdateSurveyScheduleMutation,
    useUpdateSurveyParticipantsMutation,
    useCopySurveyToOtherCompanyMutation,
    useCreateSurveyQuestionMutation,
    useUpdateSurveyQuestionMutation,
    useGetOneSurveyQuestionQuery,
    useDeleteSurveyQuestionMutation,
    useSortServeyQuestionsMutation,
    useGetSurveyProgressQuery,
    useExportSurveyResultsMutation,
    useUpdateSurveyReoccurrenceMutation,
} = surveyApiSlice;