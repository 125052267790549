import React, { useRef } from 'react'
import { Tr, Td, TdText, ButtonIcon, Actions } from '../TaskTypeForm.style'
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { MdDone, MdClear } from "react-icons/md";
import TooltipContainer from '../../../../ui-kit/ToolTip/TooltipContainer';
import { formatMessage } from '../../../../locale';
import { useDrag, useDrop } from 'react-dnd';
import { CustomFieldPermisson } from '../../../../enum/TaskPermisson/CustomFieldPermisson';
import { VscInspect } from "react-icons/vsc";

function getFieldTypeLabel(fieldType: string) {
  switch (fieldType) {
    case CustomFieldPermisson.DROPDOWN:
      return formatMessage({ id: 'taskType.dropdown', defaultMessage: 'Dropdown' });
    case CustomFieldPermisson.USER:
      return formatMessage({ id: 'taskType.user', defaultMessage: 'User' });
    case CustomFieldPermisson.DEPARTMENT:
      return formatMessage({ id: 'taskType.department', defaultMessage: 'Department' });
    case CustomFieldPermisson.OFFICE:
      return formatMessage({ id: 'taskType.office', defaultMessage: 'Office' });
    case CustomFieldPermisson.JOB_TITLE:
      return formatMessage({ id: 'taskType.jobTitle', defaultMessage: 'Job Title' });
    case CustomFieldPermisson.PROJECT_GROUP:
      return formatMessage({ id: 'taskType.groupProject', defaultMessage: 'Project Group' });
    case CustomFieldPermisson.DATE:
      return formatMessage({ id: 'taskType.date', defaultMessage: 'Date' });
    case CustomFieldPermisson.STRING:
      return formatMessage({ id: 'taskType.string', defaultMessage: 'String' });
    case CustomFieldPermisson.NUMBER:
      return formatMessage({ id: 'taskType.number', defaultMessage: 'Number' });
    case CustomFieldPermisson.EDITOR:
      return formatMessage({ id: 'taskType.editor', defaultMessage: 'Editor' });
    case CustomFieldPermisson.TaskTypesInArray:
      return formatMessage({ id: 'taskType.list', defaultMessage: 'List' });
    case CustomFieldPermisson.IMAGES:
      return formatMessage({ id: 'taskType.image', defaultMessage: 'Image' });
    default:
      return fieldType;
  }
}

function DragableCustomField({ item, isRisk, handleEditClick, handleDeleteId, viewModal, isMultiselect, intl, index, moveCustomField, onDragEnd }: any) {
  
  const ref = useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop({
    accept: 'customField',
    hover(draggedItem: { index: number }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveCustomField(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'customField',
    item: { type: 'customField', id: item.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (draggedItem, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      onDragEnd();
    },
  });

  drag(drop(ref));

  return (
    <Tr ref={ref} style={{ opacity: isDragging ? 0.8 : 1, fontWeight: 'normal', fontSize: 14 }} key={item.id}>
      <TdText style={{ textTransform: 'capitalize' }} onClick={() => viewModal(item)}>{item.translations.find((t: any) => t.language_code === intl.locale).translated_name}</TdText>
      <TdText style={{ textTransform: 'capitalize' }} onClick={() => viewModal(item)}>
        {getFieldTypeLabel(item.field_type)}
      </TdText>
      <TdText onClick={() => viewModal(item)}>ㅤ{item.is_input ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</ TdText>
      <TdText onClick={() => viewModal(item)}>ㅤ {item.is_output ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</TdText>
      <TdText onClick={() => viewModal(item)}>ㅤㅤ{item.is_required ? <MdDone size={35} style={{ color: 'green' }} /> : <MdClear size={35} style={{ color: 'red' }} />}</TdText>
      <TdText onClick={() => viewModal(item)}>ㅤㅤ{isMultiselect({ value: item.field_type }) && item.is_multiselect ? <MdDone size={35} style={{color: 'green'}} /> : <MdClear size={35} style={{color: 'red'}}/>}</TdText>
      <Td>
        <Actions>
          {isRisk ? (
          <TooltipContainer title={formatMessage({ id: 'taskType.view', defaultMessage: 'View' })}>
            <ButtonIcon type='button' onClick={() => { handleEditClick(item) }}>
              <VscInspect size={20} />
            </ButtonIcon>
          </TooltipContainer>
          ) : (
            <TooltipContainer title={formatMessage({ id: 'toolTip.edit', defaultMessage: 'Edit' })}>
              <ButtonIcon type='button' onClick={() => { handleEditClick(item) }}>
                <LuFileEdit size={18} />
              </ButtonIcon>
            </TooltipContainer>
          )}
          <TooltipContainer title={formatMessage({ id: 'toolTip.delete', defaultMessage: 'Delete' })}>
            <ButtonIcon type='button' onClick={() => {handleDeleteId(item)}}>
              <LuTrash size={18} />
            </ButtonIcon>
          </TooltipContainer>
        </Actions>
      </Td>
      <Td style={{width: 0}}></Td>
    </Tr>
  )
}

export default DragableCustomField