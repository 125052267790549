import styled from 'styled-components'

export const BackButton = styled.button`
    display: flex;
    align-self: flex-start;
    align-items: center;
    color: ${(props) => props.color};
    gap: 2px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        font-size: 12px;
    }
`;

export const Title = styled.h2`
    margin: 0;
    break-word: break-all;
`;