import styled from 'styled-components';
import { Button } from '../../../../../ui-kit';

export const FormPaper = styled.div`
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
        margin: 0 10px 0 10px;  
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    overflow: auto;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
        margin: 0 10px 0 10px;  
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

export const QuestionText = styled.p`
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    word-wrap: break-word;
    margin: 0;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
    }
`;

export const QuestionSubText = styled.p`
    font-size: 0.7rem;
    word-wrap: break-word;
    margin-left: 3rem;
    font-weight: 500;
    color: 'grey';
    text-align: left;

    @media (max-width: 1436px) {
    margin-left: 2.4rem;
    font-size: 8px;
    }

    @media (max-width: 1128px) {
        font-size: 5px;
    }
`;

export const QuestionContent = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
`;

export const RowGroup = styled.div<{ $breakpoint?: any; $columns?: number }>`
    flex: 1;
    display: grid;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    background-color: rgba(255, 255, 255, 1);

    ${({ $breakpoint, theme }) => `
        @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    `}

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
        background-color: rgba(255, 255, 255, 1);
    }
`;

export const ContainerGroup = styled.div<{ $breakpoint?: any; $columns?: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const Row = styled.div<{ $breakpoint?: any; $columns?: number }>`
    width: 100%;
    flex: 1;
    display: grid;
    gap: 1.5rem;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 50%;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(44, 39, 56, 0.04);
    background-color: rgba(255, 255, 255, 1);

    ${({ $breakpoint, theme }) => `
        @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    `}

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
        margin: 10px 0;
    }
`;

export const QuestionImage = styled.img`
    width: 30%;
    height: auto;
    margin: 10px 0;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const CenteredContainer = styled.div<{ type?: boolean }>`
    display: flex;
    align-self:  ${({  type }) => ( type ? 'center' : 'center')};
    justify-content:  ${({  type }) => ( type ? 'center' : 'center')};
    margin-top: 20px;
    width: ${({  type }) => ( type ? '80%' : '80%')};

    @media (max-width: 768px) {
        margin-top: 10px;
    }
`;

export const CommentWidth = styled.div`
    width: 80%;

    @media (max-width: 768px) {
        width: 80%;
    }
`;

export const SmilieContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    transition: transform 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }

    @media (max-width: 768px) {
        margin: 0 5px;
    }
`;

export const StyledChoiceButton = styled(Button)<{ selected: boolean }>`
  background-color: ${({ selected }) =>
    selected ? 'rgba(170, 17, 103, 1)' : 'lightGrey'};
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 0 5px;

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    font-size: 10px;
  }
`;

export const ScaleOneToTenContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    
    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;  /* Center each row */
        width: 100%;
        flex-wrap: wrap;
        margin-top: 10px;
    }
`;


export const ScaleOneToTenWrapper = styled.div`
   
`;

export const RequiredIndicatorContainer = styled.div<{ isRequired?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  gap: ${({ isRequired }) => (isRequired ? '5px' : '0px')};
`;

export const RequiredIndicatorText = styled.p`
  color: rgba(170, 17, 103, 1);
  font-weight: bold;
  margin: 0;
`;


export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;


export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
`;

export const Thead = styled.thead`
    display: flex;
    width: 100%;
    color: #000;
    text-align: center;
    margin-bottom: 1rem;
    word-break: break-word;
`;

export const Tbody = styled.tbody`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

export const Th = styled.th`
  flex: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: grey;
  
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.35rem;
  }
`;

export const TdText = styled.td`
  flex: 1;
  padding: 10px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 0.9rem;
  }
`;