import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import Button from '../../../../ui-kit/Button/Button';
import { Actions, FormHeader, FormPaper, TabContent, FormContent, Column, ColumnTopLevel } from './HandbookForm.styles';
import { paths } from '../../../../config/paths';
import { useCreateHandbookMutation, useUpdateHandbookMutation, useViewHandbookQuery } from '../../../../slices/handbooksApiSlice';
import Row from '../../../../ui-kit/grid/Row';
import { FormikInput, FormikTextarea, FormikCheckbox } from '../../../../ui-kit';
import WangEditor from '../../../../ui-kit/Form/components/Editor/WysiwygEditor';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { useGetAllDepartmentsSimpleQuery } from '../../../../slices/departmentsApiSlice';
import { useGetAllOfficesSimpleQuery } from '../../../../slices/officeApiSlice';
import { useGetAllUsersQuery } from '../../../../slices/usersApiSlices';
import { useGetAllJobTitlesSimpleQuery } from '../../../../slices/jobTitleApiSlice';
import { useGetAllProjectGroupsSimpleQuery } from '../../../../slices/projectGroupApiSlice';
import { useGetRolesCompanyNoIdQuery } from '../../../../slices/companiesApiSlice';
import { Form, Formik } from 'formik'
import { handleImageData } from '../../../../slices/apiSlice'
import { transformToTree, flattenOptions } from '../../../../slices/apiSlice'
import { useSelector } from 'react-redux';
import SaveTaskInfoModal from '../../../../ui-kit/Modal/SaveTaskInfoModal/SaveTaskInfoModal'
import { useGetAllTaskTypesListQuery } from '../../../../slices/taskTypeApiSlice';

interface HandbookFormPageProps {
  mode: 'create' | 'edit';
}

export const HandbookForm = ({ mode }: HandbookFormPageProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { handbookId } = useParams<{ handbookId: string }>();
  const navigate = useNavigate();
  const { intl } = useIntlContext();

  const [createHandbook] = useCreateHandbookMutation();
  const [updateHandbook] = useUpdateHandbookMutation();

  const [departmentTree, setDepartmentTree] = useState<any[]>([]);
  const { data: handbook, refetch } = useViewHandbookQuery({ id: handbookId, update: true }, { skip: mode === 'create' });
  const { data: taskTypes } = useGetAllTaskTypesListQuery({});

  const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
    value: option.id,
    label: option.name,
  })) || [];

  const optionsTaskType = mapOptions(taskTypes)

  const { data: departmentsData } = useGetAllDepartmentsSimpleQuery({});
  const { data: officesData } = useGetAllOfficesSimpleQuery({});
  const { data: usersData } = useGetAllUsersQuery({});
  const { data: jobTitlesData } = useGetAllJobTitlesSimpleQuery({});
  const { data: projectGroupsData } = useGetAllProjectGroupsSimpleQuery({});
  const { data: rolesCompaniesData } = useGetRolesCompanyNoIdQuery({});

  const [selectedTaskType, setSelectedTaskType] = useState<any>(null);
  const [inputButtonName, setInputButtonName] = useState<string>('');

  useEffect(() => {
    return () => {
      localStorage.removeItem('uuids');
      localStorage.removeItem('frontId');
  };
}, []);

  const departments = departmentsData?.data || [];
  const offices = officesData?.data || [];
  const users = usersData?.data || [];
  const jobTitles = jobTitlesData?.data || [];
  const projectGroups = projectGroupsData?.data || [];
  const roles = rolesCompaniesData?.data || [];

  const initialValues = {
    allUsers: handbook?.data.is_company_wide_access || false,
    is_mandatory: handbook?.data.is_mandatory || false,
    is_appeal: handbook?.data.is_appeal || false,
    departments: handbook?.data.accesses?.departments?.map((dept: any) => dept.id) || [],
    offices: handbook?.data.accesses?.offices?.map((office: any) => office.id) || [],
    job_titles: handbook?.data.accesses?.job_titles?.map((jobTitle: any) => jobTitle.id) || [],
    project_groups: handbook?.data.accesses?.project_groups?.map((projectGroup: any) => projectGroup.id) || [],
    users: handbook?.data.accesses?.users?.map((user: any) => user.id) || [],
    roles: handbook?.data?.accesses?.roles?.map((role: any) => role.id) || [], 
    title: handbook?.data.title || '',
    description: handbook?.data.description || '',
    content: handbook?.data.content || '',
    cover: handbook?.data.cover || null,
    card_color: handbook?.data.card_color || userCompany.button_color,
  };

  useEffect(() => {
    if (mode === 'edit' && handbook?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = handbook.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs));
    }
  }, [handbook?.data?.entity_files]);


  useEffect(() => {
    if (mode === 'edit' && handbookId) {
      refetch();
    }
  }, [mode, handbookId]);


  useEffect(() => {
    if (departmentsData) {
      const tree = transformToTree(departmentsData.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departmentsData]);

   useEffect(() => {
      if (mode === 'edit' && handbook?.data && taskTypes?.data) {
        setSelectedTaskType({
          value: handbook?.data?.task_type_id,
          label: taskTypes?.data?.find((taskType: any) => taskType.id === handbook?.data?.task_type_id)?.name,
        })
        setInputButtonName(handbook?.data?.btn_text)
      }
    }, [handbook, taskTypes])

  const handleSubmit = async (values: any) => {
    try {
      const cleanIds = (ids: any[], data: any[]) => ids.filter(id => data.some(item => item.id === id));
      const backUID = localStorage.getItem('uuids');
      const frontUID = localStorage.getItem('frontId');
      
      const cleanedValues = {
        ...values,
        departments: cleanIds(values.departments, departments),
        offices: cleanIds(values.offices, offices),
        users: cleanIds(values.users, users),
        job_titles: cleanIds(values.job_titles, jobTitles),
        project_groups: cleanIds(values.project_groups, projectGroups),
        roles: cleanIds(values.roles, roles),
      };
    
      let data: any = {
        is_company_wide_access: cleanedValues.allUsers ? 1 : 0,
        card_color: values.card_color,
        description: values.description,
        title: values.title,
        content: values.content,
        is_appeal: values.is_appeal ? 1 : 0,
        ...values.is_appeal && { task_type_id: selectedTaskType?.value },
        ...values.is_appeal && { btn_text: inputButtonName },
        access: {
          users: cleanedValues.users,
          job_titles: cleanedValues.job_titles,
          project_groups: cleanedValues.project_groups,
          departments: cleanedValues.departments,
          offices: cleanedValues.offices,
          roles: cleanedValues.roles,
        },
      };
      if (backUID) {
        try {
          const parsedBackUID = JSON.parse(backUID);
          if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
            data = {
              ...data,
              entity_files: {
                uuid: frontUID,
                files: parsedBackUID.map((id: any) => ({
                  id,
                  tag: ""
                })),
              },
            };
          }
        } catch (error) {
          console.error('Failed to parse backUID:', error);
        }
      }
      data = handleImageData(values, data, 'cover');
      data.status = 'published';
      if (mode === 'create') {
        await createHandbook({ data: data }).unwrap();
        localStorage.removeItem('uuids');
        localStorage.removeItem('frontId');
        navigate(paths.handbook);
      } else if (mode === 'edit' && handbookId) {
        await updateHandbook({ id: handbookId, data: data }).unwrap();
        localStorage.removeItem('uuids');
        localStorage.removeItem('frontId');
        navigate(paths.handbook);
      }
    } catch (error) {
      console.error('Failed to create/update handbook:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'handbookForm.edit', defaultMessage: 'Edit: ' }) + initialValues.title
                    : formatMessage({ id: 'handbookForm.create', defaultMessage: 'Create page' })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => {
                    navigate(paths.handbook)
                    localStorage.removeItem('uuids');
                    localStorage.removeItem('frontId');
                  }
                  }
                >
                  {formatMessage({ id: 'handbookForm.cancel', defaultMessage: 'Cancel' })}
                </Button>
                <Button appearance="primary" type="submit"  >
                  {mode === 'create'
                    ? formatMessage({ id: 'handbookForm.publish', defaultMessage: 'Publish' })
                    : formatMessage({ id: 'handbookForm.save', defaultMessage: 'Save' })}
                </Button>
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <ColumnTopLevel>
                  <Row>
                    <FormikInput
                      label={formatMessage({ id: 'handbookForm.titleLabel', defaultMessage: 'Title' })}
                      name="title"
                      placeholder={formatMessage({ id: 'handbookForm.titlePlaceholder', defaultMessage: 'Enter a handbook title' })}
                      autoComplete="off"
                      required
                    />
                  </Row>
                  <Row>
                    <FormikTextarea
                      label={formatMessage({ id: 'handbookForm.descriptionLabel', defaultMessage: 'Description' })}
                      name="description"
                      placeholder={formatMessage({ id: 'handbookForm.descriptionPlaceholder', defaultMessage: 'Add a short description' })}
                      autoComplete="off"
                      required
                    />
                  </Row>
                  <Row>
                    <WangEditor name="content" />
                  </Row>
                  <Row>
                    <FormikInput
                      label={formatMessage({ id: 'handbookForm.cardColorLabel', defaultMessage: 'Card color' })}
                      name="card_color"
                      placeholder={formatMessage({ id: 'handbookForm.cardColorPlaceholder', defaultMessage: 'Enter a handbook card color' })}
                      autoComplete="off"
                      type="color"
                    />
                  </Row>
                  <Row>
                    <ImageUploadWrapper name={'cover'} type={'photos'}></ImageUploadWrapper>
                  </Row>
                </ColumnTopLevel>
                <Column>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10}}>
                      <FormikCheckbox
                        label={formatMessage({
                          id: 'investigationForm.checkBox',
                          defaultMessage: 'All users',
                        })}
                        name="allUsers"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const isChecked = e
                          setFieldValue('allUsers', isChecked)
                          if (isChecked) {
                            setFieldValue('departments', [])
                            setFieldValue('offices', [])
                            setFieldValue('job_titles', [])
                            setFieldValue('project_groups', [])
                            setFieldValue('users', [])
                            setFieldValue('roles', [])
                          }
                        }}
                        />
                      {values.allUsers && (
                        <FormikCheckbox
                        label={formatMessage({ id: 'task.createTask', defaultMessage: 'Skapa aktivitet' })}
                        name="is_appeal"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const isChecked = e
                          setFieldValue('is_appeal', isChecked)
                          if (!isChecked) {
                            setSelectedTaskType(null)
                            setInputButtonName('')
                          }
                        }}
                      />
                      )}
                    </div>
                  {!values.allUsers && (     
                    <>
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.departmentLabel', defaultMessage: 'Department/Business Unit/Team' })}
                        name="departments"
                        options={departmentTree}
                        values={values.departments}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.officeLabel', defaultMessage: 'Office' })}
                        name="offices"
                        options={offices.map((office: any) => ({ value: office.id, label: office.name }))}
                        values={values.offices}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.jobTitleLabel', defaultMessage: 'Job Title' })}
                        name="job_titles"
                        options={jobTitles.map((jobTitle: any) => ({ value: jobTitle.id, label: jobTitle.name }))}
                        values={values.job_titles}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.groupProjectLabel', defaultMessage: 'Project group' })}
                        name="project_groups"
                        options={projectGroups.map((projectGroup: any) => ({ value: projectGroup.id, label: projectGroup.name }))}
                        values={values.project_groups}
                        onChange={setFieldValue}
                      />
                      <MultiSelect
                        label={formatMessage({ id: 'handbookForm.userLabel', defaultMessage: 'User' })}
                        name="users"
                        options={users.map((user: any) => ({ value: user.id, label: user.fullname }))}
                        values={values.users}
                        onChange={setFieldValue}
                      />
                     <MultiSelect
                        label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
                        name="roles"
                        options={roles.map((role: any) => ({ value: role.id, label: `${role.title}` })) || []}
                        values={values.roles}
                        onChange={(field, value) => {
                        setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? [] : value)}}
                      />
                    </>
                  )}
                   {!values.allUsers && (
                    <FormikCheckbox
                    label={formatMessage({ id: 'task.createTask', defaultMessage: 'Skapa aktivitet' })}
                    name="is_appeal"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = e
                      setFieldValue('is_appeal', isChecked)
                      if (!isChecked) {
                        setSelectedTaskType(null)
                        setInputButtonName('')
                      }
                    }}
                  />
                  )}
                  {values.is_appeal &&
                  <SaveTaskInfoModal
                  color={userCompany?.button_color}
                  value={selectedTaskType}
                  setSelectedTaskType={setSelectedTaskType}
                  options={optionsTaskType}
                  buttonName={inputButtonName}
                  setButtonName={setInputButtonName}
                  />}
                </Column>
              </FormContent>
            </TabContent>
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};

export default HandbookForm;
