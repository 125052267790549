import styled from 'styled-components';

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const Content = styled.div`
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-left: 16px;
`;
