import useMediaQuery from '../hooks/useMediaQuery'
import { lightTheme } from '../theme/theme'

export const useMedia = () => {
  const isTablet = useMediaQuery(`(max-width: ${lightTheme.breakPoints.md})`)
  const isMobile = useMediaQuery(`(max-width: ${lightTheme.breakPoints.sm})`)
  const isWide = useMediaQuery(`(max-width: 1128px)`)
  return {
    isTablet,
    isMobile,
    isWide
  }
}
