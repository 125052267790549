import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatMessage } from '../../../../locale';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { RiExpandUpDownFill } from "react-icons/ri";
import { FaSortAmountDownAlt, FaSortAmountDown } from "react-icons/fa";
import { useSelector } from 'react-redux';

interface CardProps {
    id: string;
    title: string;
    taskType: string;
    assignee: string;
    custom_field_list_values: any[];
    custom_field: any;
    created_at: any;
}

export const Actions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

export const Actions2 = styled.div`
  position: absolute;
  top: 15px;
  left: 30px;
  display: flex;
  gap: 10px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

const CardsWrapper = styled.div`
   width: 100%;
    overflow-x: auto;
`;

 const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

 const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

 const Tbody = styled.tbody`
    background-color: #fff;
`

 const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`

 const Th = styled.th`
    padding: 15px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

 const Td = styled.td`
  padding: 15px;
  border-bottom: 1px solid #ddd;
`

export const Card: React.FC<CardProps> = ({
  id,
  title,
  taskType,
  assignee,
  created_at,
  custom_field_list_values,
  custom_field,
}) => {
  const { intl } = useIntlContext();
  
   const { userInfo, permissions, userCompany } = useSelector((state: any) => state.auth);

  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.map((val: any, i: number) => (
        <span key={i}>
          {val.name ||
            val.translations?.find((t: any) => t.language_code === intl.locale)?.translated_value ||
            val.option_value ||
            val}
          {i < value.length - 1 && ', '}
        </span>
      ));
    } else if (typeof value === 'object' && value !== null) {
      if (value.date) {
        return <span>{new Date(value.date).toLocaleDateString()}</span>;
      }
      return Object.entries(value).map(([key, val], i) => (
        <span key={i}>
          {key}: {val as any}
          {i < Object.entries(value).length - 1 && ', '}
        </span>
      ));
    }
    return <span>{value}</span>;
  };

  const hasMatchingValues = (group: any) => {
    return custom_field.some((field: any) => {
      const groupData = group.data?.find((item: any) => item.custom_field_id === field.id);
      return groupData && groupData.value;
    });
  };

  return (
    <>
      {custom_field_list_values.map((group: any, groupIndex: number) => (
        hasMatchingValues(group) && (
          <Tr key={`${id}-${groupIndex}`}>
            <Td style={{ textTransform: 'capitalize', width: '15%', color: userCompany?.main_color, textDecoration: 'underline' }}>
              {title}
            </Td>
            <Td style={{ width: '15%' }}>{taskType}</Td>
            {custom_field.map((field: any) => {
              const groupData = group.data?.find((item: any) => item.custom_field_id === field.id);
              return (
                <Td key={field.id} style={{ width: '15%', textTransform: 'capitalize' }}>
                  {groupData ? renderValue(groupData.value) : '-'}
                </Td>
              );
            })}
            <Td style={{ width: '15%' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                {`${assignee}`}
              </div>
            </Td>
            <Td style={{ width: '20%' }}>
              {new Date(created_at).toLocaleDateString()}
            </Td>
            <Td style={{ padding: 5 }}></Td>
          </Tr>
        )
      ))}
    </>
  );
};

const DropdownOption: React.FC<{ sortOrder: any, selected: boolean, onClick: () => void, icon: React.ReactNode, label: string }> = ({ sortOrder, selected, onClick, icon, label }) => (
  <p
    style={{
      margin: 0,
      gap: 10,
      padding: '10px',
      cursor: 'pointer',
      fontWeight: 'normal',
      backgroundColor: selected ? '#a1cef8' : 'transparent',
      borderTopRightRadius: sortOrder === 'asc' ? 5 : 0,
      borderTopLeftRadius: sortOrder === 'asc' ? 5 : 0,
      borderBottomLeftRadius: sortOrder === 'desc' ? 5 : 0,
      borderBottomRightRadius: sortOrder === 'desc' ? 5 : 0,
    }}
    onClick={onClick}
  >
    {icon}
    {label}
  </p>
);

export const CardGrid: React.FC<{ cards: any }> = ({ cards }) => {
  const { intl } = useIntlContext();
  const [sortedCards, setSortedCards] = useState(cards);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [dropdownOpen, setDropdownOpen] = useState(false);


  useEffect(() => {
    const sorted = [...cards].sort((a, b) => {
      const dateA = new Date(a.created_at || '').getTime();
      const dateB = new Date(b.created_at || '').getTime();

      if (!a.created_at) return sortOrder === 'asc' ? 1 : -1; 
      if (!b.created_at) return sortOrder === 'asc' ? -1 : 1; 

      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortedCards(sorted);
  }, [cards, sortOrder]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && !(event.target as HTMLElement).closest('.dropdown-container')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortOrderChange = (order: 'asc' | 'desc') => {
    setSortOrder(order);
    setDropdownOpen(false);
  };

  return (
    <CardsWrapper>
      <Table style={{ marginTop: 20 }}>
        <Thead>
          <Tr>
            <Th>
              {formatMessage({ id: 'dashboard.taskTitle', defaultMessage: 'Activity title' })}
            </Th>
            <Th>
            {formatMessage({ id: 'dashboard.taskType', defaultMessage: 'Activity type' })}
            </Th>
            {cards[0]?.custom_field?.map((field: any) => (
              <Th key={field.id}>
                {field.translations.find((t: any) => t.language_code === intl.locale)?.translated_name || field.field_type}
              </Th>
            ))}
            <Th>{formatMessage({ id: 'task.assign', defaultMessage: 'Assignee' })}</Th>
            <Th>{formatMessage({ id: 'dashboard.createdDate', defaultMessage: 'Date' })}</Th>
            <Th style={{ padding: 5 }}>
              <div className="dropdown-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: dropdownOpen ? 'lightGrey' : 'transparent', borderRadius: 5 }} onClick={toggleDropdown}>
                <RiExpandUpDownFill size={25} />
              </div>
              {dropdownOpen && (
                <div className="dropdown-container" style={{ position: 'absolute', right: '2%', marginTop: 5, backgroundColor: 'white', borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  <DropdownOption
                    selected={sortOrder === 'asc'}
                    onClick={() => handleSortOrderChange('asc')}
                    icon={<FaSortAmountDownAlt size={12} style={{ fontWeight: 'normal', marginRight: 5 }} />}
                    label={formatMessage({ id: 'task.assending', defaultMessage: 'Ascending' })}
                    sortOrder={sortOrder}
                  />
                  <DropdownOption
                    selected={sortOrder === 'desc'}
                    onClick={() => handleSortOrderChange('desc')}
                    icon={<FaSortAmountDown size={12} style={{ fontWeight: 'normal', marginRight: 5 }} />}
                    label={formatMessage({ id: 'task.descending', defaultMessage: 'Descending' })}
                    sortOrder={sortOrder}
                  />
                </div>
              )}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedCards && sortedCards.length > 0 && (
            sortedCards.map((card: any) => (
              card.custom_field_list_values.map((group: any, groupIndex: number) => (
                <Card key={`${card.id}-${groupIndex}`} {...card} custom_field_list_values={[group]} />
              ))
            ))
          )}
        </Tbody>
      </Table>
    </CardsWrapper>
  );
};

